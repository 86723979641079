import React, {Component} from "react"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import {addAlert} from "../App/actions"
import * as MyActions from "./NewInvoicePrintApi"
import {
    Button,
    Checkbox,
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    Grid, InputGroup,
    Panel,
    Radio,
    Row,
    Well,
} from "react-bootstrap"
import Helmet from "react-helmet"
import PageHeader from "../../components/PageTitle"
import {showEmailDialog} from "../../components/email/actions"
import {NewInvoicePrintOutput} from "./NewInvoicePrintOutput";
import ProposalMenu from "../MapViewPage/new/ProposalMenu";
import "../MapViewPage/new/ProposalMenu.css";
import './NewInvoicePrint.css';
import {saveLogoSettings} from "../../actions/printWorkOrders"
import InputGroupAddon from "react-bootstrap/lib/InputGroupAddon";
import {getValidFontSize, validateFontSize, windowPrint} from "../../common/pdfPrintUtils";
import PrintOptionsHistoryModal from "../PrintProposalPage/PrintOptionsHistoryModal";
import axios from "axios";
import MDSpinner from "react-md-spinner";

const Actions = {...MyActions, addAlert, showEmailDialog, saveLogoSettings};
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

class NewInvoicePrint extends Component {
    state = {
        bounds: null,
        showLogoHeader: false,
        logoHeight: null,
        invoice: null,
        iframeKey: 0,
        user_print_options: {},
        isPrinted: false,
        errors: {},
        isHistoryModalOpen: false,
        downloadInProgress: false,
        printButtonPushed: false,
        downloadButtonPushed: false,
    };

    resetIframe = () => {
        this.setState({iframeKey: this.state.iframeKey + 1});
    }

    handleSaveSettings = () => {
        const {logoHeight, logoPosition} = this.state
        this.props.actions.saveLogoSettings(
            this.state.invoice.client_id, logoHeight, logoPosition)
    }

    handleFormChange = e => {
        if (e.target.type === "checkbox") {
            let checked = e.target.type === "checkbox"
                ? e.target.checked
                : e.target.value === "true";
            if (e.target.name === "showLogo") {
                this.setState({showLogoHeader: !this.state.showLogoHeader})
            }
            this.state.invoice.print_options[e.target.name] = checked;
            this.props.actions.updateInvoicePrintOptions(this.props.params.invoiceId, this.state.invoice.print_options, () => this.setState({invoice: this.state.invoice}));
        } else {
            this.state.invoice.print_options[e.target.name] = +e.target.value
            this.props.actions.updateInvoicePrintOptions(this.props.params.invoiceId, this.state.invoice.print_options, () => this.setState({invoice: this.state.invoice}));
        }


        if (e.target.name === 'fitBounds') {
            this.state.invoice.print_options['maps'] = false;
            this.setState({invoice: this.state.invoice}, () => {
                this.state.invoice.print_options['maps'] = true;
                this.setState({invoice: this.state.invoice});
            })
        }
    };

    handleFontSizeOnBlur = (optionName) => {
        const {errors, invoice} = this.state;
        const updatedFontSize = getValidFontSize(invoice.print_options[optionName]);
        const updatedErrors = {...errors};
        if(updatedErrors[optionName]){
            delete updatedErrors[optionName];
        }

        const updatedPrintOptions = {...invoice.print_options, [optionName]: updatedFontSize}
        this.props.actions.updateInvoicePrintOptions(this.props.params.invoiceId, updatedPrintOptions, ()=> this.setState({invoice: {...invoice, print_options: updatedPrintOptions}, errors: updatedErrors}));
    }
    makeDefault = () => {
        const print_options = this.state.invoice.print_options
        const current_user_id = this.props.user.user_id
        this.props.actions.updateUserInvoicePrintOptions(current_user_id, print_options, (response) => {
            this.setState({user_print_options: response.invoice_print_options})
        });
    }
    resetToDefault = () => {
        const invoice = this.state.invoice
        const user_print_options = this.state.user_print_options
        user_print_options && this.props.actions.resetInvoicePrintOptions(this.props.params.invoiceId, user_print_options, (response) => {
            this.setState({
                invoice: {...invoice, print_options: response.print_options},
                showLogoHeader: response.print_options.showLogo
            })
        });

    }
    renderPrintOptionsHeader = () => {
        const {email} = this.props;
        const {invoice, downloadInProgress, printButtonPushed, downloadButtonPushed} = this.state;
        const printOptions = invoice && invoice.print_options;
        const {marginTop, marginBottom, atTheEnd} = this.state.invoice.print_options;
        const {thumbnailSize, wrapHorizontally} = this.state.invoice.print_options;

        return (printOptions && <Panel
                bsStyle="primary"
                collapsible
                defaultExpanded={true}
                header="Print Options"
                className="hidden-print well-filter"
            >
                <Row>
                    <Col xs={12}>
                        <p>
                            The Invoice can be customized before printing. Checked items are
                            included in the printout.
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col className="options" xs={12}>
                        <Well bsSize="small">
                            <h5>Prices</h5>
                            <FormGroup>
                                <Checkbox
                                    name="tax"
                                    checked={printOptions.tax || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Tax
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="servicePrices"
                                    checked={printOptions.servicePrices || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Subtotals
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="balance"
                                    checked={printOptions.balance || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Balance
                                </Checkbox>
                            </FormGroup>
                        </Well>

                        <Well bsSize="small">
                            <h5>Notes</h5>
                            <FormGroup>
                                <Checkbox
                                    name="assetNotes"
                                    checked={printOptions.assetNotes || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Asset Notes
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="proposalServiceNotes"
                                    checked={printOptions.proposalServiceNotes || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Service Notes
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="proposalCustomerNote"
                                    checked={printOptions.proposalCustomerNote || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    <div>
                                        <div>Proposal Note</div>
                                        <Checkbox
                                            name="notesAfterServices"
                                            checked={printOptions.notesAfterServices || false}
                                            disabled={!printOptions.proposalCustomerNote}
                                            onChange={this.handleFormChange}
                                            inline
                                        >
                                            After services
                                        </Checkbox>
                                    </div>
                                    <div>
                                        <Checkbox
                                            name="notesBeforeServices"
                                            checked={printOptions.notesBeforeServices || false}
                                            disabled={!printOptions.proposalCustomerNote}
                                            onChange={this.handleFormChange}
                                            inline
                                        >
                                            Before services
                                        </Checkbox>
                                    </div>
                                </Checkbox>
                            </FormGroup>
                        </Well>

                        <Well bsSize="small">
                            <h5>Maps</h5>
                            <FormGroup>
                                <Checkbox
                                    name="maps"
                                    checked={printOptions.maps || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    <div>
                                        <div>Maps</div>
                                        <Checkbox
                                            name="fitBounds"
                                            checked={printOptions.fitBounds || false}
                                            disabled={!printOptions.maps}
                                            onChange={this.handleFormChange}
                                            inline
                                        >
                                            Fit Bounds
                                        </Checkbox>
                                    </div>
                                </Checkbox>
                                {invoice.site_maps.map((sitemap, i) => (
                                        <div key={sitemap.id}>
                                            <Checkbox
                                                name={`maps_${sitemap.id}`}
                                                checked={printOptions[`maps_${sitemap.id}`] || false}
                                                onChange={this.handleFormChange}
                                                inline
                                            >
                                                Sitemap {i + 1}
                                            </Checkbox>
                                        </div>
                                    )
                                )}
                            </FormGroup>
                        </Well>

                        <Well bsSize="small">
                            <h5>Assets</h5>
                            <FormGroup>
                                <Checkbox
                                    name="largeAssetPhotos"
                                    checked={printOptions.largeAssetPhotos || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Asset Photos
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="assetPhotosCustomerView"
                                    checked={printOptions.assetPhotosCustomerView || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Asset Photos in customer view
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="locationDescription"
                                    checked={printOptions.locationDescription || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Location Description
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="dbh"
                                    checked={printOptions.dbh || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    DBH
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="condition"
                                    checked={printOptions.condition || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Condition
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="lat"
                                    checked={printOptions.lat || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Latitude
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="lng"
                                    checked={printOptions.lng || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Longitude
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="height"
                                    checked={printOptions.height || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Height
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="width"
                                    checked={printOptions.width || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Width
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="stems"
                                    checked={printOptions.stems || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Stems
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="factors"
                                    checked={printOptions.factors || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Factors
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="wrapVertically"
                                    checked={printOptions.wrapVertically || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Wrap Vertically
                                </Checkbox>
                            </FormGroup>
                        </Well>
                        <Well bsSize="small">
                            <h5>Services</h5>
                            <FormGroup>
                                <Checkbox
                                    name="serviceDateRanges"
                                    checked={printOptions.serviceDateRanges || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Service Date Ranges
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="servicePurchaseOrder"
                                    checked={printOptions.servicePurchaseOrder || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Service Purchase Order
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="servicePriorities"
                                    checked={printOptions.servicePriorities || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Priorities
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="thumbAssetPhotos"
                                    checked={printOptions.thumbAssetPhotos || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Thumbnail Asset Photos
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="allowPageBreakingWithinService"
                                    checked={printOptions.allowPageBreakingWithinService}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Allow Breaking Between Pages
                                </Checkbox>
                            </FormGroup>
                        </Well>

                        <Well bsSize="small">
                            <h5>Charts</h5>
                            <FormGroup>
                                <Checkbox
                                    name="assetSummary"
                                    checked={printOptions.assetSummary || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >Asset Summary</Checkbox>
                                <br/>
                                <Checkbox
                                    name="serviceType"
                                    checked={printOptions.serviceType || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >Service Type</Checkbox>
                                <br/>
                                <Checkbox
                                    name="serviceSummary"
                                    checked={printOptions.serviceSummary || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >Service Summary</Checkbox>
                                <br/>
                            </FormGroup>
                        </Well>

                        <Well bsSize="small">
                            <h5>Options</h5>
                            <FormGroup>
                                <Checkbox
                                    name="invoiceSiteName"
                                    checked={printOptions.invoiceSiteName || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Invoice Site Name
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="invoiceSiteAddress"
                                    checked={printOptions.invoiceSiteAddress || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Invoice Site Address
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="arboristInfo"
                                    checked={printOptions.arboristInfo || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Arborist Info
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="assInline"
                                    checked={printOptions.assInline || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Assets Inline
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="purchaseOrder"
                                    checked={printOptions.purchaseOrder || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Purchase Order
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="showLogo"
                                    checked={printOptions ? printOptions?.showLogo || false : true}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Show Company Logo
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="showPageNumbers"
                                    checked={printOptions.showPageNumbers || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Show Page Numbers
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="showCompanyAddress"
                                    checked={printOptions.showCompanyAddress || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Show Company Address
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="showPaidImage"
                                    checked={printOptions.showPaidImage || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Show Paid Image
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="showInvoicePayments"
                                    checked={printOptions.showInvoicePayments || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Show Invoice Payments
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="showTerms"
                                    checked={printOptions.showTerms || false}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Show Terms and Conditions
                                </Checkbox>
                            </FormGroup>
                        </Well>
                        {this.state.showLogoHeader &&
                        <Well bsSize="small">
                            <h5>Logo options</h5>
                            <FormGroup>
                                <FormControl
                                    name="logoHeight"
                                    type="text"
                                    placeholder="Height"
                                    value={this.state.logoHeight ? this.state.logoHeight : ''}
                                    onChange={e => this.setState({logoHeight: e.target.value})}
                                    onBlur={this.handleSaveSettings}
                                />
                                <Radio
                                    name="logoPosition"
                                    checked={this.state.logoPosition === 'left'}
                                    onChange={e => this.setState({[e.target.name]: e.target.value}, () => this.handleSaveSettings())}
                                    value={'left'}
                                    inline
                                >Left</Radio>
                                <br/>
                                <Radio
                                    name="logoPosition"
                                    checked={this.state.logoPosition === 'center'}
                                    onChange={e => this.setState({[e.target.name]: e.target.value}, () => this.handleSaveSettings())}
                                    value={'center'}
                                    inline
                                >Center</Radio>
                                <br/>
                                <Radio
                                    name="logoPosition"
                                    checked={this.state.logoPosition === 'right'}
                                    onChange={e => this.setState({[e.target.name]: e.target.value}, () => this.handleSaveSettings())}
                                    value={'right'}
                                    inline
                                >Right</Radio>
                                <br/>
                            </FormGroup>
                        </Well>}
                        <Well bsSize="small">
                            <h5>Map options</h5>
                            <div className='d-flex'>
                                <div className='columnDirection full-width'>
                                    <FormGroup>
                                        <Checkbox
                                            name="atTheEnd"
                                            checked={atTheEnd || false}
                                            onChange={this.handleFormChange}
                                            inline
                                        >
                                            Show at the end
                                        </Checkbox>
                                    </FormGroup>
                                    <FormGroup className='space-between'>
                                        <ControlLabel className='flex-basis-40'>
                                            Margin Top
                                        </ControlLabel>
                                        <FormControl
                                            className='flex-basis-60'
                                            name="marginTop"
                                            type="text"
                                            placeholder="Top"
                                            style={{maxWidth: 120}}
                                            value={marginTop}
                                            onChange={this.handleFormChange}
                                        />
                                    </FormGroup>
                                    <FormGroup className='space-between'>
                                        <ControlLabel className='flex-basis-40'>
                                            Margin Bottom
                                        </ControlLabel>
                                        <FormControl
                                            className='flex-basis-60'
                                            name="marginBottom"
                                            type="text"
                                            placeholder="Bottom"
                                            style={{maxWidth: 120}}
                                            value={marginBottom}
                                            onChange={this.handleFormChange}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </Well>
                        {printOptions.thumbAssetPhotos &&
                        <Well bsSize="small">
                            <h5>Thumbnail options</h5>
                            <div className='d-flex'>
                                <div className='columnDirection full-width'>
                                    <FormGroup>
                                        <Checkbox
                                            name="wrapHorizontally"
                                            checked={wrapHorizontally || false}
                                            onChange={this.handleFormChange}
                                            inline
                                        >
                                            Wrap Horizontally
                                        </Checkbox>
                                    </FormGroup>
                                    <FormGroup className='space-between'>
                                        <ControlLabel className='mr-10'>
                                            Size
                                        </ControlLabel>
                                        <FormControl
                                            name="thumbnailSize"
                                            type="text"
                                            placeholder="Size"
                                            style={{maxWidth: 120}}
                                            value={thumbnailSize || 180}
                                            onChange={this.handleFormChange}
                                        />

                                    </FormGroup>
                                </div>
                            </div>
                        </Well>}
                        <Well bsSize="small">
                            <h5>Font options</h5>
                            {['Header', 'Content', 'Footer'].map((label) => {
                                const optionName = label.toLowerCase() + "FontSize";
                                return (<FormGroup>
                                    <div className='space-between'>
                                        <ControlLabel className='flex-basis-30'>
                                            {label}
                                        </ControlLabel>
                                        <InputGroup className="flex-basis-70">
                                            <FormControl
                                                name={optionName}
                                                type="number"
                                                step={1}
                                                min={13}
                                                max={28}
                                                value={this.state.invoice.print_options[optionName] || ''}
                                                onChange={e => {
                                                    const {invoice, errors} = this.state;
                                                    const newFontSize = +e.target.value;
                                                    this.setState({
                                                        invoice: {
                                                            ...invoice,
                                                            print_options: {...invoice.print_options, [optionName]: newFontSize }
                                                        },
                                                        errors: validateFontSize(optionName, newFontSize, errors)
                                                    })
                                                }}
                                                onBlur={() => this.handleFontSizeOnBlur(optionName)}
                                            />
                                            <InputGroupAddon>
                                                px
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </div>
                                    {this.state.errors[optionName] &&
                                        <div className='error'>{this.state.errors[optionName]}</div>}
                                </FormGroup>)
                            })}
                        </Well>
                    </Col>
                </Row>
                <Row className='ml0'>
                    <div className="hright flex-wrap mr-15">
                        <Button
                            bsSize="small"
                            onClick={() => this.setState({isHistoryModalOpen: true})}
                            bsStyle="primary"
                            className='ml-6 mt-3'
                        >
                            Options History
                        </Button>
                        <Button
                            bsSize="small"
                            onClick={() => this.resetToDefault()}
                            bsStyle="primary"
                            className='ml-6 mt-3'
                        >
                            Reset to Default
                        </Button>
                        <br/>
                        <Button
                            bsSize="small"
                            onClick={() => this.makeDefault()}
                            bsStyle="primary"
                            className='ml-6 mt-3'
                        >
                            Make Default
                        </Button>
                        <br/>
                        <Button
                            bsSize="small"
                            disabled={downloadInProgress}
                            onClick={() => this.setState({downloadInProgress: true, downloadButtonPushed: true}, () => this.props.actions.downloadPdf(invoice,
                                () => this.setState({downloadInProgress: false, downloadButtonPushed: false}),
                                () => this.setState({downloadInProgress: false, downloadButtonPushed: false})
                            ))}
                            bsStyle="primary"
                            className='ml-6 mt-3'
                        >
                            {downloadButtonPushed ? <div><MDSpinner size={20} className="mr-5"/>Processing...</div> : 'Download PDF'}
                        </Button>
                        <br/>
                        <Button
                            bsSize="small"
                            disabled={downloadInProgress}
                            onClick={() => {
                                isMobile
                                    ? windowPrint()
                                    : this.setState({isPrinted: true, downloadInProgress: true, printButtonPushed: true}, () => this.props.actions.printPdf(invoice,
                                        () => this.setState({downloadInProgress: false, printButtonPushed: false}),
                                        () => this.setState({downloadInProgress: false, printButtonPushed: false})
                                    ))
                            }}
                            bsStyle="primary"
                            className='ml-6 mt-3'
                        >
                            {printButtonPushed ? <div><MDSpinner size={20} className="mr-5"/>Processing...</div> : 'Print'}
                        </Button>
                        <br/>
                        <Button
                            bsSize="small"
                            onClick={this.showEmailDialog}
                            bsStyle="primary"
                            className='ml-6 mt-3'
                        >
                            Email
                        </Button>
                    </div>
                </Row>
            </Panel>
        )
    };

    setEmailRecipients = (value) => {
        let baseValue = null
        const {contact_email, contact_email2, secondContactEmail, secondContactEmail2} = value
        const primarySiteContactEmails = (contact_email && contact_email2) ? `${contact_email}, ${contact_email2}` : contact_email
        const secondarySiteContactEmails = (secondContactEmail && secondContactEmail2) ? `${secondContactEmail}, ${secondContactEmail2}` : secondContactEmail

        baseValue = `${primarySiteContactEmails || ""}`

        if (secondarySiteContactEmails) baseValue += `, ${secondarySiteContactEmails}`

        return baseValue
    }

    showEmailDialog = () => {
        const {email, client} = this.props;
        const {invoice} = this.state;

        let recipientEmail
        let ccRecipientEmail
        let billingEmail = invoice.email_address
        recipientEmail = this.setEmailRecipients(invoice)
        ccRecipientEmail = [recipientEmail, invoice?.arborist_email].map(email => ({email_address: email}))
        this.props.actions.showEmailDialog(
            {
                emailType: invoice.paid_at ? 'invoice paid' : 'invoice',
                referenceId: invoice.id,
                recipient: billingEmail,
                defaultEmail: email,
                cc_recipients: ccRecipientEmail,
                dataPdf: invoice,
                client: client
            });
    };

    componentDidMount() {
        const id = this.props.params.invoiceId;
        if (id) {
            this.reload(id);
        }
    }

    reload(id) {
        this.props.actions.getInvoice(id, (result) => {
            let invoice = result;
            invoice.print_options = JSON.parse(result.print_options);
            if(invoice.client_header) {
                axios.get(invoice.client_header).catch(() => {
                    invoice.client_header = 'https://s3-us-east-2.amazonaws.com/treereserve/production/images/trees.150605.1710640155.original.png?1710640155'
                });
            }
            this.setState({
                invoice: invoice,
                logoHeight: invoice.logo_height,
                logoPosition: invoice.logo_position,
                showLogoHeader: invoice.print_options?.showLogo
            });
        });
        this.props.actions.fetchUser(this.props.user.user_id, (user) => {
            this.setState(
                {user_print_options: user.invoice_print_options}
            )

        })
    }

    formatPrintOptions = (options) => {
        return {
            ...options,
            headerFontSize: getValidFontSize(options.headerFontSize),
            contentFontSize: getValidFontSize(options.contentFontSize),
            footerFontSize: getValidFontSize(options.footerFontSize),
        }
    }

    render() {
        const {invoice, isHistoryModalOpen} = this.state;
        if (!invoice) {
            return <div>Loading...</div>
        }
        return (
            <div>
                <Grid fluid style={{maxWidth: "100%"}}>
                    <div className="hidden-print">
                        <ProposalMenu
                            id={invoice.proposal_id}
                            expandButton={false}
                        />
                    </div>
                    <Col md={12}>
                        <Helmet>
                            <title>{`Invoice #${invoice.number}`}</title>
                            <style type="text/css">
                                {`
                          @page {
                          size: 8.5in 11in;
                          margin: 0.25in 0.25in 0.25in 0.25in;
                          }
                       `}
                            </style>
                        </Helmet>
                    </Col>
                    <div className="hidden-print">
                        <PageHeader
                            pageName="Print Invoice"
                            pageDetail={`Invoice #${invoice.number}`}
                        />
                    </div>
                    {invoice && this.renderPrintOptionsHeader()}
                </Grid>
                <br/>
                <NewInvoicePrintOutput serviceType={invoice.print_options.serviceType}
                                       assetSummary={invoice.print_options.assetSummary}
                                       serviceSummary={invoice.print_options.serviceSummary}
                                       logoHeight={this.state.logoHeight}
                                       logoPosition={this.state.logoPosition}
                                       showLogoHeader={this.state.showLogoHeader} invoice={invoice && invoice}
                                       options={invoice && this.formatPrintOptions(invoice.print_options)} client={this.props.client}/>

                {isHistoryModalOpen && <PrintOptionsHistoryModal
                    isOpen={isHistoryModalOpen}
                    close={() => this.setState({isHistoryModalOpen: false})}
                    invoice={invoice}
                />}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        email: state.auth.email,
        client: state.client.customerInfo,
        user: state.auth
    }
};

const mapDispatchToProps = dispatch => {
    return {actions: bindActionCreators(Actions, dispatch)}
};

export default connect(mapStateToProps, mapDispatchToProps)(NewInvoicePrint)
