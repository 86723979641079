import React from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {addAlert} from '../App/actions'
import {showEmailDialog} from "../../components/email/actions"
import * as MyActions from './NewInvoiceApi'
import {
    Button,
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    Glyphicon,
    Grid,
    MenuItem,
    NavDropdown,
    OverlayTrigger,
    Panel,
    Row,
    Tooltip,
    Well,
} from 'react-bootstrap'
import {LinkContainer} from "react-router-bootstrap"
import Helmet from 'react-helmet'
import moment from "moment";
import {defaultDateFormat, select, sortAssetsLabels, updateExternalLinks} from "../../common/commonHandlers";
import Select from "react-select";
import ResourceComponent from "../../components/ResourceComponent";
import Dollars from "../../components/Dollars"
import TermModal from "./TermModal";
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import ProposalMenu from "../MapViewPage/new/ProposalMenu";
import {Link} from "react-router";
import PlantName from "../../components/PlantName";
import BundleServices from "../BundleServices/BundleServices";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PONumberInvoiceCreationModal from "./PONumberInvoiceCreationModal";
import InlineEditable from "../Scheduler/InlineEditable";

const Actions = {...MyActions, addAlert, showEmailDialog};
const isMobile = window.screen.width <= 1024;

class NewInvoice extends ResourceComponent {

    constructor(props) {
        super(props);
        this.state = {
            resource: {
                label: 'Create Invoice'
            },
            term_types: [],
            selected_proposal_services: [],
            work_orders: [],
            all_proposal_services: [],
            work_order_id: null,
            showTermModal: false,
            selectedInvoice: null,
            defaultTerm: true,
            invoices: [],
            selectedInvoiceId: null,
            newInvoiceAmount: null,
            allPayments: [],
            loading: false,
            downloadInProgress: false,
        };
    }

    onInvoiceNoteChange = (e, i) => {
        let proposal_services = this.state.resource.proposal_services
        proposal_services[i].invoice_notes = e.target.value
        this.setState({resource: {...this.state.resource, proposal_services}})
    }

    updateInvoiceNote = (ps_idx) => {
        const proposal_service = this.state.resource.proposal_services[ps_idx]
        this.props.actions.updateProposalService(proposal_service.proposal_service_id, {invoice_notes: proposal_service.invoice_notes})
    }

    handleSelectService = (e, id) => {
        let {selected_proposal_services} = this.state;
        if (!selected_proposal_services.includes(id)) {
            selected_proposal_services.push(id);
            this.setState(selected_proposal_services)
        } else {
            selected_proposal_services.splice(selected_proposal_services.indexOf(id), 1);
            this.setState(selected_proposal_services)
        }
        this.setState(selected_proposal_services);
    };

    handleSelectAllServices = (e, proposal_services) => {
        let {selected_proposal_services} = this.state;
        if (e.target.checked) {
            proposal_services.forEach(ps => ['Completed'].includes(ps.status) && !ps.invoice_id && selected_proposal_services.push(ps.proposal_service_id));
            this.setState(selected_proposal_services);
        } else {
            this.setState({selected_proposal_services: []});
        }
    };

    setEmailRecipients = (value) => {
        let baseValue = null
        const {
            contact_email,
            contact_email2,
            secondContactEmail,
            secondContactEmail2,
            additionalContactEmails,
            additionalContactEmails2
        } = value
        const primarySiteContactEmails = (contact_email && contact_email2) ? `${contact_email}, ${contact_email2}` : contact_email
        const secondarySiteContactEmails = (secondContactEmail && secondContactEmail2) ? `${secondContactEmail}, ${secondContactEmail2}` : secondContactEmail

        baseValue = `${primarySiteContactEmails || ""}`

        if (secondarySiteContactEmails) baseValue += `, ${secondarySiteContactEmails}`
        if (additionalContactEmails?.length > 0) baseValue += `, ${additionalContactEmails.toString()}`
        if (additionalContactEmails2?.length > 0) baseValue += `, ${additionalContactEmails2.toString()}`

        return baseValue
    }

    showEmailDialog = (send_email = false) => {
        const {email} = this.props;
        let {allInvoices, selectedInvoiceId, resource} = this.state;

        if (send_email) {
            this.props.actions.getInvoice({
                id: resource.id,
                work_order_id: resource.work_order_id
            }, async (result) => {
                allInvoices = result.proposal_services.map(ps => ps.invoice_id && ({
                    value: {
                        id: ps.invoice_id,
                        email_address: ps.invoice.email_address,
                        site_email_addresses: ps.invoice.site_email_addresses
                    },
                    label: `Add to Invoice #${ps.invoice_no}`
                })).filter(element => element != null)

                let recipientEmail
                let ccRecipientEmail
                const invoice = allInvoices.find(i => i.value.id === selectedInvoiceId)
                const selectedInvoice = allInvoices.find(i => i.value.id === selectedInvoiceId) && allInvoices.find(i => i.value.id === selectedInvoiceId).value
                recipientEmail = selectedInvoice ? this.setEmailRecipients(selectedInvoice) : ''
                let billingEmail = selectedInvoice?.email_address
                recipientEmail = recipientEmail.split(',').filter(email => email?.trim() !== billingEmail?.trim()).join(',')
                ccRecipientEmail = [recipientEmail, selectedInvoice?.arborist_email].filter(e => e && e?.length > 0).map(email => ({email_address: email}))

                if (selectedInvoiceId) {
                    this.props.actions.showEmailDialog(
                        {
                            emailType: selectedInvoice?.paid_at ? 'invoice paid' : 'invoice',
                            referenceId: selectedInvoiceId,
                            recipient: billingEmail,
                            cc_recipients: ccRecipientEmail,
                            defaultEmail: email,
                            dataPdf: selectedInvoice,
                            client: this.props.client
                        });
                }
            })
        } else {
            let recipientEmail
            let ccRecipientEmail
            const invoice = allInvoices.find(i => i.value.id === selectedInvoiceId)
            const selectedInvoice = allInvoices.find(i => i.value.id === selectedInvoiceId) && allInvoices.find(i => i.value.id === selectedInvoiceId).value
            recipientEmail = this.setEmailRecipients(invoice && invoice.value)
            let billingEmail = selectedInvoice.email_address || ""
            recipientEmail = recipientEmail.split(',').filter(email => email?.trim() !== billingEmail?.trim()).join(',')
            ccRecipientEmail = [recipientEmail, selectedInvoice?.arborist_email].filter(e => e && e?.length > 0).map(email => ({email_address: email}))

            this.props.actions.showEmailDialog(
                {
                    emailType: selectedInvoice.paid_at ? 'invoice paid' : 'invoice',
                    referenceId: selectedInvoiceId,
                    recipient: billingEmail,
                    cc_recipients: ccRecipientEmail,
                    defaultEmail: email,
                    dataPdf: selectedInvoice,
                    client: this.props.client,
                    sendInBackground: true
                });
        }
    };

    renderServiceList = (proposal_services) => {
        const {selected_proposal_services, downloadInProgress} = this.state;
        let {resource} = this.state;
        return (proposal_services.map((service, i) => {


            const renderTooltip = service && (
                <div>
                    {service.created_at &&
                        <span>Draft {moment(service.created_at).format(defaultDateFormat)}<br/></span>}
                    {service.proposal_date &&
                        <span>Proposed {moment(service.proposal_date).format(defaultDateFormat)}<br/></span>}
                    {service.accepted_date &&
                        <span>Accepted {moment(service.accepted_date).format(defaultDateFormat)}<br/></span>}
                    {service.scheduled_date &&
                        <span>Scheduled {moment(service.scheduled_date).format(defaultDateFormat)}<br/></span>}
                    {service.completed_at &&
                        <span>Completed {moment(service.completed_at).format(defaultDateFormat)}<br/></span>}
                    {service.invoiced_at &&
                        <span>Invoiced {moment(service.invoiced_at).format(defaultDateFormat)}<br/></span>}
                    {service.invoice && service.invoice.payments && service.invoice.payments.length > 0 &&
                        <span>Payment {moment(service.invoice.payments[0].payment_date).format(defaultDateFormat)}<br/></span>}
                    {service.paid_at &&
                        <span>Paid {moment(service.paid_at).format(defaultDateFormat)}<br/></span>}
                    {service.declined_date &&
                        <span>Declined {moment(service.declined_date).format(defaultDateFormat)}</span>}
                </div>
            );
            let statusDate = null;
            if (service.proposalServiceStatus === 'Draft')
                statusDate = service.created_at && moment(service.created_at).format(defaultDateFormat);
            if (service.status === 'Invoiced')
                statusDate = service.invoiced_at && moment(service.invoiced_at).format(defaultDateFormat);
            if (service.status === 'Proposed')
                statusDate = service.proposal_date && moment(service.proposal_date).format(defaultDateFormat);
            if (service.status === 'Accepted')
                statusDate = service.accepted_date && moment(service.accepted_date).format(defaultDateFormat);
            if (service.status === 'Scheduled')
                statusDate = service.scheduled_date && moment(service.scheduled_date).format(defaultDateFormat);
            if (service.status === 'Completed')
                statusDate = service.completed_at && moment(service.completed_at).format(defaultDateFormat);
            if (service.status === 'Paid')
                statusDate = service.paid_at && moment(service.paid_at).format(defaultDateFormat);
            if (service.status === 'Declined')
                statusDate = service.declined_date && moment(service.declined_date).format(defaultDateFormat);
            if (service.status === 'Payment')
                statusDate = service.invoice && service.invoice.payments && service.invoice.payments.length > 0 && moment(service.invoice.payments[0].payment_date).format(defaultDateFormat);

            return <Panel key={i}>
                {isMobile ? <div>
                        <Row>
                            <Col xs={6}>#</Col>
                            <Col xs={6}><label>
                                <input
                                    type="checkbox"
                                    disabled={
                                        !['Completed'].includes(service.status) || service.invoice_id
                                    }
                                    onChange={e => this.handleSelectService(e, service.proposal_service_id)}
                                    checked={selected_proposal_services.includes(service.proposal_service_id)}
                                />
                                {' '}
                                #{service.order_number_in_proposal}
                            </label></Col>
                        </Row>
                        <Row>
                            <Col xs={6}>Service</Col>
                            <Col xs={6}>{service.service_name}</Col>
                        </Row>
                        <Row>
                            <Col xs={6}>Priority</Col>
                            <Col xs={6}>
                                <strong>
                                    {service.priorities && service.priorities.length > 0 ? 'Priority: ' : null}
                                    {service.priorities && service.priorities.length > 0 &&
                                        service.priorities.map(p => <span
                                            key={p.id}>{p.name}{service.priorities.length > 1 ? ', ' : ' '}</span>)}
                                </strong>
                                {service.date_ranges.length
                                    ? service.date_ranges.map(dr => (
                                        <div key={dr.id}>
                                            Typical Timing:
                                            {" "}
                                            {moment(dr.from_date).format("MMMM Do")}
                                            {" "}
                                            -
                                            {" "}
                                            {moment(dr.to_date).format("MMMM Do")}
                                        </div>
                                    ))
                                    : null}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>Status</Col>
                            <Col xs={6}>
                                <OverlayTrigger placement="top" overlay={<Tooltip>{renderTooltip}</Tooltip>}>
                                    <div>
                                        <strong>{service.status}</strong>{' '}
                                        {statusDate}
                                    </div>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>Est Hrs</Col>
                            <Col xs={6}>{service.est_hours}</Col>
                        </Row>
                        <Row>
                            <Col xs={6}>Price</Col>
                            <Col xs={6}><Dollars amount={service.price}/></Col>
                        </Row>
                        <Row>
                            <Col xs={6}>WO #</Col>
                            <Col xs={6}>{service.work_order_no &&
                                <span className='small-margin'> #{service.work_order_no} </span>}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>Invoice</Col>
                            <Col xs={6}>{service.invoice_no &&
                                <div className='flex-column-start'>
                                    <div><span className='small-margin'>
                                <NavDropdown
                                    eventKey={2}
                                    title={'#' + service.invoice_no}
                                    className="menu-show-fixer proposal-menu-padding-reset"
                                >
                                <LinkContainer to={`/new_invoice_print/${service.invoice_id}`}>
                                    <MenuItem>Print</MenuItem>
                                </LinkContainer>
                                <li className='pointer'>
                                    <Link
                                        onClick={() => !downloadInProgress && this.setState({downloadInProgress: true}, () => this.props.actions.downloadPdf(service.invoice,
                                            () => this.setState({downloadInProgress: false}),
                                            () => this.setState({downloadInProgress: false})
                                        ))}
                                        className={service.invoice_id ? '' : 'disabled'}>PDF</Link>
                                </li>
                                <li className='pointer'>
                                    <Link
                                        onClick={() => this.setState({selectedInvoiceId: service.invoice_id}, this.showEmailDialog)}
                                        className={service.invoice_id ? '' : 'disabled'}>Email</Link>
                                </li>
                            </NavDropdown>
                            </span>
                                        {!resource?.proposalInfo?.site_moved &&
                                            <OverlayTrigger placement="top" overlay={service.invoice?.payments?.length > 0 ? <Tooltip>
                                                <div>You have initialized payments</div>
                                            </Tooltip> : <div/>}>
                                                <a
                                                    style={{cursor: 'pointer'}}
                                                    onClick={() => {
                                                        if (!(service.invoice?.payments?.length > 0)) {
                                                            this.props.actions.removeInvoiceProposalService({
                                                                invoice_id: service.invoice_id,
                                                                ps_id: service.proposal_service_id
                                                            }, this.reload)
                                                        }
                                                    }}
                                                >

                          <span className={`text-danger ${service.invoice?.payments?.length > 0 ? 'disabled' : ''}`}>
                            <Glyphicon glyph="remove-sign"/>
                          </span>
                                                </a>
                                            </OverlayTrigger>}
                                    </div>
                                </div>}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>Purchase Order</Col>
                            <Col xs={6}><InlineEditable
                                form={() =>
                                    <FormControl
                                        maxLength={30}
                                        name={`purchase_order${i}`}
                                        placeholder="PO number"
                                        value={resource.proposal_services[i].purchase_order}
                                        onBlur={()=>this.updatePONumberInPS(i)}
                                        onChange={(e)=>{
                                            resource.proposal_services[i].purchase_order=e.target.value
                                            this.setState({resource})}}
                                    />
                                }
                                value={
                                    <span className="word-break">{service.purchase_order}</span>
                                }/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>Term</Col>
                            <Col xs={6}><span className={`pointer ${this.state.loading ? 'text-gray' : 'text-green'}`}
                                              onClick={() => {
                                                  if (!this.state.loading) {
                                                      this.showTermModal(service.invoice_id, service.invoice_no,
                                                          service.due_date, service.term_type_id, service.invoiced_at, service.proposal_service_id, service.paid_at,
                                                          service.invoice && service.invoice.invoice_total, service.invoice_token,
                                                          service.invoice && service.invoice.payments ? service.invoice.payments : null,
                                                          service.invoice && service.invoice.payments ? service.invoice.enable_payments_client : null)
                                                  }
                                              }
                                              }>{service.term_name && service.term_name}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>Due Date</Col>
                            <Col xs={6}>{service.due_date && moment(service.due_date).format(defaultDateFormat)}</Col>
                        </Row>
                        {service.invoice && service.invoice.enable_payments_client && <Row>
                            <Col xs={12}>
                                <ColorCheckbox value={service.invoice.enable_payments}
                                               label='Enable Stripe Payments'
                                               className='vertical-align'
                                               onChange={e => {
                                                   resource.proposal_services.filter(ps => ps.invoice && service.invoice && ps.invoice.id === service.invoice.id).forEach(ps => {
                                                       ps.invoice.enable_payments = e;
                                                   })
                                                   const params = {};
                                                   params.id = service.invoice.id;
                                                   params.enable_payments = e;
                                                   this.props.actions.enablePayments(params, this.setState(resource));
                                               }}
                                />
                            </Col>
                            <Col xs={12}>
                                <ColorCheckbox
                                    value={service.invoice.enable_ach_payments}
                                    label='Enable ACH Payments'
                                    className='vertical-align'
                                    onChange={e => {
                                        resource.proposal_services.filter(ps => ps.invoice && service.invoice && ps.invoice.id === service.invoice.id).forEach(ps => {
                                            ps.invoice.enable_ach_payments = e;
                                        });
                                        const params = {};
                                        params.id = service.invoice.id;
                                        params.enable_ach_payments = e;
                                        this.props.actions.enableACHPayments(params, () => this.setState(resource));
                                    }}
                                />
                            </Col>
                        </Row>}
                    </div>
                    : <Row>
                        <Col md={1}>
                            <label>
                                <input
                                    type="checkbox"
                                    disabled={
                                        !['Completed'].includes(service.status) || service.invoice_id
                                    }
                                    onChange={e => this.handleSelectService(e, service.proposal_service_id)}
                                    checked={selected_proposal_services.includes(service.proposal_service_id)}
                                />
                                {' '}
                                #{service.order_number_in_proposal}
                            </label>
                        </Col>
                        <Col md={resource && resource.proposal_services && resource.enable_payments_client ? 1 : 2}>
                            {service.service_name}
                        </Col>
                        <Col xs={1}>
                            <strong>
                                {service.priorities && service.priorities.length > 0 ? 'Priority: ' : null}
                                {service.priorities && service.priorities.length > 0 &&
                                    service.priorities.map(p => <span
                                        key={p.id}>{p.name}{service.priorities.length > 1 ? ', ' : ' '}</span>)}
                            </strong>


                            {service.date_ranges.length
                                ? service.date_ranges.map(dr => (
                                    <div key={dr.id}>
                                        Typical Timing:
                                        {" "}
                                        {moment(dr.from_date).format("MMMM Do")}
                                        {" "}
                                        -
                                        {" "}
                                        {moment(dr.to_date).format("MMMM Do")}
                                    </div>
                                ))
                                : null}
                        </Col>
                        <Col xs={1}>
                            <OverlayTrigger placement="top" overlay={<Tooltip>{renderTooltip}</Tooltip>}>
                                <div>
                                    <strong>{service.status}</strong>{' '}
                                    {statusDate}
                                </div>
                            </OverlayTrigger>
                        </Col>
                        <Col xs={1}>
                            {service.est_hours}
                        </Col>

                        <Col xs={1}>
                            <Dollars amount={service.price}/>
                        </Col>
                        <Col xs={1}>
                            {service.work_order_no && <span className='small-margin'> #{service.work_order_no} </span>}
                        </Col>
                        <Col xs={1}>
                            {service.invoice_no &&
                                <div className='flex-column-start'>
                                    <div className='small-margin justify-flex-start'><span className='small-margin'>
                                <NavDropdown
                                    noCaret
                                    eventKey={2}
                                    title={'#' + service.invoice_no}
                                    className="menu-show-fixer proposal-menu-padding-reset"
                                >
                                <LinkContainer to={`/new_invoice_print/${service.invoice_id}`}>
                                    <MenuItem>Print</MenuItem>
                                </LinkContainer>
                                <li className='pointer'>
                                    <Link
                                        onClick={() => !downloadInProgress && this.setState({downloadInProgress: true}, () => this.props.actions.downloadPdf(service.invoice,
                                            () => this.setState({downloadInProgress: false}),
                                            () => this.setState({downloadInProgress: false})
                                        ))}
                                        className={service.invoice_id ? '' : 'disabled'}>PDF</Link>
                                </li>
                                <li className='pointer'>
                                    <Link
                                        onClick={() => this.setState({selectedInvoiceId: service.invoice_id}, this.showEmailDialog)}
                                        className={service.invoice_id ? '' : 'disabled'}>Email</Link>
                                </li>
                                <li className='pointer'>
                                        <Link to={`/quickbooks?invoice_id=${service.invoice_id}`}>
                                            Show in Quickbooks
                                        </Link>
                                        </li>
                               <li className='pointer'>
                                    <Link
                                        to={{pathname: updateExternalLinks(null, `https://test.treehub.me/customer_view/invoice/${service.invoice.token}`)}}
                                        target="_blank">Customer View</Link>
                                </li>
                            </NavDropdown>
                            </span>
                                        {!resource?.proposalInfo?.site_moved &&
                                            <OverlayTrigger placement="top"
                                                            overlay={service.invoice?.payments?.length > 0 ? <Tooltip>
                                                                <div>You have initialized payments</div>
                                                            </Tooltip> : <div/>}>
                                                <a
                                                    style={{cursor: 'pointer'}}
                                                    onClick={() => {
                                                        if (!(service.invoice?.payments?.length > 0)) {
                                                            this.props.actions.removeInvoiceProposalService({
                                                                invoice_id: service.invoice_id,
                                                                ps_id: service.proposal_service_id
                                                            }, this.reload)
                                                        }
                                                    }
                                                    }
                                                >
                          <span className={`text-danger ${service.invoice?.payments?.length > 0 ? 'disabled' : ''}`}>
                            <Glyphicon glyph="remove-sign"/>
                          </span>
                                                </a>
                                            </OverlayTrigger>}
                                    </div>
                                </div>}
                        </Col>
                        <Col xs={1}>
                            <InlineEditable
                                form={() =>
                                    <FormControl
                                        maxLength={30}
                                        name={`purchase_order${i}`}
                                        placeholder="PO number"
                                        value={resource.proposal_services[i].purchase_order}
                                        onBlur={()=>this.updatePONumberInPS(i)}
                                        onChange={(e)=>{
                                            resource.proposal_services[i].purchase_order=e.target.value
                                            this.setState({resource})}}
                                    />
                                }
                                value={
                                    <span className="word-break">{service.purchase_order}</span>
                                }
                                editMessage={!service.purchase_order}
                                length={resource.proposal_services[i].purchase_order?.length}
                            />
                        </Col>
                        <Col xs={1}>
                        <span className={`pointer ${this.state.loading ? 'text-gray' : 'text-green'}`}
                              onClick={() => {
                                  if (!this.state.loading) {
                                      this.showTermModal(service.invoice_id, service.invoice_no, service.due_date, service.term_type_id,
                                          service.invoiced_at, service.proposal_service_id, service.paid_at,
                                          service.invoice && service.invoice.invoice_total, service.invoice_token,
                                          service.invoice && service.invoice.payments ? service.invoice.payments : null,
                                          service.invoice && service.invoice.payments ? service.invoice.enable_payments_client : null)
                                  }
                              }}>{service.term_name && service.term_name}</span>
                        </Col>
                        <Col xs={1}>
                            {service.due_date && moment(service.due_date).format(defaultDateFormat)}
                        </Col>
                        {service.invoice && service.invoice.enable_payments_client ? <Col md={1}>
                            <ColorCheckbox value={service.invoice.enable_payments}
                                           label='Enable Stripe Payments'
                                           className='vertical-align'
                                           onChange={e => {
                                               resource.proposal_services.filter(ps => ps.invoice && service.invoice && ps.invoice.id === service.invoice.id).forEach(ps => {
                                                   ps.invoice.enable_payments = e;
                                               })
                                               const params = {};
                                               params.id = service.invoice.id;
                                               params.enable_payments = e;
                                               this.props.actions.enablePayments(params, () => {
                                                   this.setState(resource)
                                               });
                                           }}
                            />
                            <ColorCheckbox
                                value={service.invoice.enable_ach_payments}
                                label='Enable ACH Payments'
                                className='vertical-align'
                                onChange={e => {
                                    resource.proposal_services.filter(ps => ps.invoice && service.invoice && ps.invoice.id === service.invoice.id).forEach(ps => {
                                        ps.invoice.enable_ach_payments = e;
                                    });
                                    const params = {};
                                    params.id = service.invoice.id;
                                    params.enable_ach_payments = e;
                                    this.props.actions.enableACHPayments(params, () => this.setState(resource));
                                }}
                            />
                        </Col> : <Col xs={1}></Col>}
                    </Row>}
                <Row>
                    <Col xs={12}>
                        <span><strong>Trees:{" "}</strong></span>
                        {service.assets.length && service.assets.sort((a, b) => sortAssetsLabels(a, b, 'asset_label')).map((asset, idx) => {
                                return (
                                    <span
                                        key={idx}>{`#${asset.asset_label} `}<PlantName plant={asset.name}/>,
                            </span>
                                );
                            }
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <div><strong>Service Notes:</strong></div>
                        {service.service_note ?
                            <div className='pre-line'
                                 dangerouslySetInnerHTML={{__html: service.service_note}}/> : 'Service Notes'}
                        <div><strong>Work Order Notes:</strong></div>
                        {service.work_order_note ?
                            <div className='pre-line'
                                 dangerouslySetInnerHTML={{__html: service.work_order_note}}/> : 'Work Order Notes'}
                    </Col>
                    <Col xs={12} md={6}>
                        <FormGroup bsSize="small">
                            <ControlLabel><strong>Invoice Notes:</strong></ControlLabel>
                            <FormControl
                                componentClass="textarea"
                                rows={3}
                                name="invoice-notes"
                                placeholder="Invoice Notes"
                                value={service.invoice_notes}
                                onChange={(e) => {
                                    this.onInvoiceNoteChange(e, i)
                                }}
                                onBlur={() => {
                                    this.updateInvoiceNote(i)
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                {service.is_bundle_service &&
                    <BundleServices
                        bundleServices={service.bundle_services}
                        view='NewInvoice'
                        markedCheckboxes={selected_proposal_services}
                        proposalServiceId={service.proposal_service_id}
                    />
                }
            </Panel>
        }))
    };

    loadWorkOrders = () => {
        const {resource} = this.state;
        this.props.actions.getWorkOrders(resource.id, work_orders => {
            this.setState({work_orders})
        });
    };

    handleWoFilter = e => {
        let resource = {...this.state.resource};
        const {all_proposal_services} = this.state;
        if (!e) {
            this.setState({work_order_id: null}, () => {
                resource.proposal_services = all_proposal_services;
                this.setState({resource});
            });
        } else {
            this.setState({work_order_id: e.value}, () => {
                resource.proposal_services = all_proposal_services.filter(ps => ps.work_order_id === e.value);
                this.setState({resource});
            })
        }
    };

    saveInvoice = () => {
        const {resource, selected_proposal_services, defaultTerm, term_types} = this.state;
        let wos = [];
        let ps_update = [];
        let proposal = {};
        let amount = 0;
        proposal.id = resource.id;
        selected_proposal_services.map(ps => {
            let selectedPS = resource.proposal_services.find(p => ps === p.proposal_service_id);
            amount += resource.proposal_services.find(p => ps === p.proposal_service_id).price;
            selectedPS.work_order_id !== null && wos.push(selectedPS.work_order_id);
            ps_update.push(selectedPS.proposal_service_id)
        });
        proposal.proposal_services = ps_update;
        if (resource.label === 'Create Invoice') {
            if (defaultTerm && term_types.length && term_types.find(tt => tt.default_term)?.id) {
                this.save();
            } else {
                this.setState({showTermModal: true, newInvoiceAmount: amount});
            }
        } else if (resource.label === 'Create & Email') {
            if (defaultTerm && term_types.length && term_types.find(tt => tt.default_term)?.id) {
                this.save();
                this.showEmailDialog(true);
            } else {
                this.setState({showTermModal: true, newInvoiceAmount: amount});
            }
        } else {
            selected_proposal_services.forEach(sps => {
                this.props.actions.createInvoicePropoosalService({
                    invoice_id: resource.label,
                    proposal_service_id: sps
                }, this.reload);
            });
        }
    };

    save = (purchase_order = null) => {
        const {resource, selected_proposal_services, defaultTerm, term_types} = this.state;
        let wos = [];
        let total = 0;
        selected_proposal_services.map(ps => {
            let selectedPS = resource.proposal_services.find(p => ps === p.proposal_service_id);
            selectedPS.work_order_id !== null && wos.push(selectedPS.work_order_id);
            total += selectedPS.price;
        });
        let invoice = {};
        invoice.payments = resource.payments;
        invoice.invoiced_at = defaultTerm ? moment() : resource.invoiced_at;
        invoice.total = total;
        invoice.customer_id = resource.proposalInfo.customerId;
        invoice.proposal_id = resource.id;
        invoice.site_id = resource.site_id;
        invoice.paid_at = resource.paid_at;
        invoice.ps_id = resource.ps_id ? resource.ps_id : null;
        invoice.due_date = (defaultTerm && term_types.length && term_types.find(tt => tt.default_term)?.term) ? moment(moment(), "DD-MM-YYYY").add((term_types.length && term_types.find(tt => tt.default_term).term), 'days').endOf('day') : moment().endOf('day');
        invoice.wo_ids = wos;
        invoice.term_type_id = defaultTerm && (term_types.length && term_types.find(tt => tt.default_term)?.id) ? (term_types.length && term_types.find(tt => tt.default_term)?.id) : resource.term_type_id;
        invoice.proposal_service_ids = selected_proposal_services;
        this.props.actions.saveInvoice(invoice, purchase_order, result => {
            this.setState({selectedInvoiceId: result.id}, () => {
                this.reload();
                this.setState({showPONumberModal: false})
                if (resource.label === 'Create & Email') {
                    this.showEmailDialog(true);
                }
            })
        });
    };

    reload = () => {
        let {resource} = this.state;
        const id = this.props.params.proposalId;
        this.setState({loading: true})
        this.props.actions.load(resource.id, result => this.setState({...result}, () => {
            this.props.actions.getManualPaymentTypes((res) => {
                let manualPaymentTypes = res.map((pt) => {
                    pt.label = pt.name
                    pt.value = pt.id
                    return pt
                })
                this.setState({
                    manualPaymentTypes
                })
            })
            if (id) {
                resource.id = parseInt(id);
            }
            this.setState(resource, () => {
                if (resource.id) {
                    this.loadWorkOrders();
                    this.search();
                }
            });
        }));
    };

    showTermModal = (invoice_id, invoice_no, due_date, term_type_id, invoiced_at, proposal_service_id, paid_at, invoice_total, invoice_token, payments, enable_payments_client) => {
        if (invoice_id) {
            let invoice = {};
            invoice.id = invoice_id;
            invoice.total = invoice_total;
            invoice.token = invoice_token;
            invoice.no = invoice_no;
            invoice.due_date = due_date;
            invoice.term_type_id = term_type_id;
            invoice.invoiced_at = invoiced_at;
            invoice.paid_at = paid_at;
            invoice.proposal_service_id = proposal_service_id;
            invoice.payments = payments;
            invoice.enable_payments_client = enable_payments_client;
            this.setState({selectedInvoice: invoice}, () => this.setState({showTermModal: true}));
        } else {
            this.setState({selectedInvoice: null}, () => this.setState({showTermModal: true}));
        }
    };

    componentDidMount() {
        this.reload();
    }

    handleProposalIdChange = (id) => {
        let {resource} = this.state;
        resource.id = id;
        this.setState(resource, this.reload);
    };

    onEmailSend = (id) => {
        let {resource} = this.state;
        resource.id = id;
        this.setState(resource, this.reload);
    }

    closeTermModal = () => {
        this.setState({showTermModal: false, selectedInvoice: null, newInvoiceAmount: null});
    };
    updateTermAttributes = (data, invoice) => {
        const {resource} = this.state;
        if (!invoice) {
            resource.invoiced_at = moment(data.invoiced_at);
            resource.term_type_id = data.term_type_id;
            resource.due_date = moment(data.due_date);
            resource.paid_at = moment(data.paid_at);
            resource.ps_id = null;
            this.setState({resource: resource, showTermModal: false, selectedInvoice: null}, this.save);
        } else {
            let invoiceParams = {};
            invoiceParams.invoiced_at = moment(data.invoiced_at);
            invoiceParams.term_type_id = data.term_type_id;
            invoiceParams.due_date = moment(data.due_date);
            invoiceParams.paid_at = moment(data.paid_at);
            invoiceParams.ps_id = data.proposal_service_id;
            this.props.actions.updateInvoice(invoice.id, invoiceParams, () => {
                const psIndex = resource.proposal_services.findIndex(ps => ps.proposal_service_id === invoiceParams.ps_id)
                resource.proposal_services[psIndex] = {...resource.proposal_services[psIndex], ...invoiceParams}
                this.reload();
                this.setState({showTermModal: false, selectedInvoice: null, resource})
            })
        }
    };

    groupBy = (xs, f) => {
        return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
    };

    search = () => {
        const {resource, selectedInvoice} = this.state;
        this.props.actions.getProposalInvoices(resource.id, (result) => {
            let allPayments = []
            let uniqPayments = []
            result.proposal_services.forEach(x => {
                allPayments = allPayments.concat(x.invoice && x.invoice.payments)
            })
            allPayments.filter(y => y && y.id).forEach(x => {
                if (!uniqPayments.map(payment => payment && payment.id).includes(x.id)) {
                    uniqPayments.push(x)
                }
            })

            if (selectedInvoice) {
                selectedInvoice.paid_at = result?.proposal_services?.filter(ps => ps.invoice_id === selectedInvoice?.id)[0]?.paid_at
            }

            this.setState({
                resource: {...this.state.resource, ...result},
                all_proposal_services: result.proposal_services,
                allPayments: uniqPayments.filter(p => p.status !== 'refunded'),
                allInvoices: result.proposal_services.map(ps => ps.invoice_id && ({
                    value: ps.invoice,
                    label: ps.invoice_no
                })).filter(element => element != null),
                invoices: result.proposal_services.map(ps => ps.invoice_id && ({
                    value: ps.invoice_id,
                    label: `Add to Invoice #${ps.invoice_no}`
                })).filter(element => element != null),
                selected_proposal_services: [],
                loading: false,
                selectedInvoice
            });
        });
    }

    removeDuplicatesInvoices = (values) => {
        return values.reduce((unique, o) => {
            if (!unique.some(obj => obj.number === o.number)) {
                unique.push(o);
            }
            return unique;
        }, []);
    }

    updatePONumber = () =>{
        const {purchase_order} = this.state.resource
        const proposal_id = this.state.resource?.id
        this.props.actions.updatePONumber(proposal_id, purchase_order)
    }

    updatePONumberInPS = (i) =>{
        const {proposal_services} = this.state.resource
        const ps = proposal_services[i]
        this.props.actions.updatePONumberInPS(ps.proposal_service_id, ps.purchase_order)
    }

    renderEditWidget() {
        const {
            resource,
            selected_proposal_services,
            work_orders,
            work_order_id,
            defaultTerm,
            term_types,
            invoices,
            all_proposal_services,
            allPayments,
            allInvoices
        } = this.state;
        let invoiceOptions = [
            {label: 'Create Invoice', value: 'Create Invoice'},
            {label: 'Create & Email', value: 'Create & Email'}
        ].concat(invoices);
        invoiceOptions = invoiceOptions.filter((option, index, self) =>
                index === self.findIndex((t) => (
                    t.label === option.label && t.value === option.value
                ))
        );
        const defaultTermLabel = term_types.length && term_types.find(tt => tt.default_term)?.name;
        const servicesLeft = this.state.all_proposal_services.filter(ps => ['Completed'].includes(ps.status) && !ps.invoice_id).length;
        let totalBillable, totalPaid, totalSelected, totalInvoiced, totalPastDue;
        totalBillable = totalPaid = totalSelected = totalInvoiced = totalPastDue = 0;
        this.state.all_proposal_services.filter(ps => ['Completed'].includes(ps.status) && !ps.invoice_id).map(ps => {
            totalBillable += ps.price
        });

        this.state.selected_proposal_services.map(psid => {
            totalSelected += this.state.all_proposal_services.find(ps => (ps.proposal_service_id === psid)).price
        });

        const total = this.state.all_proposal_services.filter(y => ['Invoiced', 'Payment', "Paid"].includes(y.status)).map(x => x.price).reduce((a, b) => a + b, 0)
        totalPaid = allPayments.map(x => x.amount).reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        totalInvoiced = total - totalPaid


        totalPastDue = 0
        let totalInvoices = []
        all_proposal_services.filter(ps => ['Invoiced', 'Payment'].includes(ps.status) && moment().isAfter(moment(ps.due_date))).map(ps => {
            allInvoices && allInvoices.forEach(invoice => {
                let invoiceTotal = 0
                let invoicePayments = 0

                totalInvoices = totalInvoices.concat(invoice.value)
                totalInvoices = this.removeDuplicatesInvoices(totalInvoices)
                totalInvoices = totalInvoices.filter(i => moment().isAfter(moment(i.due_date)))
                if (totalInvoices && totalInvoices.length > 0) {
                    invoiceTotal = totalInvoices.map(inv => parseFloat(inv.invoice_total)).reduce((a, b) => a + b, 0)
                    invoicePayments = totalInvoices.map(i => i.payments && i.payments.length > 0 ? i.payments.map(p => parseFloat(p.amount)).reduce((a, b) => a + b, 0) : []).filter(item => item !== null && !Array.isArray(item) && item.length !== 0).reduce((a, b) => a + b, 0)
                }

                totalPastDue = invoiceTotal - invoicePayments
            })
        })


        return (<div>
                <Row className="top15">
                    <Col md={3}>
                        <div><strong className="small-margin">With checked Proposal Services...</strong></div>
                        <Select className="Select" classNamePrefix="select"
                                placeholder="Option"
                                defaultValue={resource.label}
                                value={select(invoiceOptions, resource.label)}
                                onChange={e => {
                                    this.selectResourceAttr('label')(e);

                                }}
                                options={invoiceOptions}
                        />
                    </Col>
                    <Col md={2}>
                        <div><strong className="small-margin">Use default term</strong></div>
                        <ColorCheckbox value={defaultTerm}
                                       label={defaultTermLabel}
                                       onChange={(e) => this.setState({defaultTerm: e})}
                        />
                    </Col>
                    <Col md={3}>
                        <div><strong className="small-margin">Select WO #</strong></div>
                        <Select className="Select"
                                classNamePrefix="select"
                                value={select(work_orders, work_order_id)}
                                options={work_orders}
                                placeholder="Work Order #"
                                isClearable
                                onChange={e => this.handleWoFilter(e)}
                        />
                    </Col>
                    <Col md={2}>
                        <FormGroup bsSize="small">
                            <ControlLabel><strong>Purchase Order:</strong></ControlLabel>
                            <div className="d-flex">
                                <FormControl
                                    className="mr-5"
                                    maxLength={30}
                                    name="purchase_order"
                                    placeholder="Purchase order"
                                    value={resource.purchase_order}
                                    onBlur={this.updatePONumber}
                                    onChange={this.updateResourceAttr}
                                />
                                {all_proposal_services.filter(ps => ['Completed'].includes(ps.status) && !ps.invoice_id).length !== 0 && <Button
                                    bsSize="small"
                                    bsStyle="success"
                                    onClick={() => this.setState({showPONumberModal: true})}
                                >
                                    <FontAwesomeIcon icon="plus"/>
                                </Button>}
                            </div>
                        </FormGroup>
                    </Col>
                    <Col xs={2}>
                        <div className='small-margin'>
                            <p>
                                <strong className={'text-warning'}>Services Remaining:&nbsp;
                                </strong>{servicesLeft}
                            </p>
                            <p>
                                <strong className={'text-warning'}>Billable:&nbsp;
                                </strong>
                                <Dollars amount={parseFloat(totalBillable)}/>
                            </p>
                            <p>
                                <strong className={'text-info'}>Selected:&nbsp;
                                </strong>
                                <Dollars amount={parseFloat(totalSelected)}/>
                            </p>
                            <p>
                                <strong className={'text-secondary'}>Invoiced:&nbsp;
                                </strong>
                                <Dollars amount={parseFloat(totalInvoiced)}/>
                            </p>
                            <p>
                                <strong className={'text-primary'}>Past Due:&nbsp;
                                </strong>
                                <Dollars amount={parseFloat(totalPastDue)}/>
                            </p>
                            <p>
                                <strong className="text-blue">Paid:&nbsp;
                                </strong>
                                <Dollars amount={parseFloat(totalPaid)}/>
                            </p>

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="top40">
                        <Button
                            disabled={selected_proposal_services.length < 1}
                            onClick={this.saveInvoice}
                            bsSize="small"
                            bsStyle="success"
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    };

    handlePaymentDelete = (currentPayment) => {
        this.props.actions.removePayment(currentPayment, () => {
            this.reload();
        });
    };

    handlePaymentUpdate = (payment) => {

        this.props.actions.updatePayment(payment, () => {
            this.reload();
        });
    };

    handlePaymentAdd = (token, amount, payment_date, payment_type_id, reference_no, callback) => {
        if (amount !== 0) {
            this.props.actions.addPayment(token, amount, payment_date, payment_type_id, reference_no, result => {
                this.reload()
                this.setState({
                    paid: result.paid,
                    showTermModal: false,
                }, () => callback && callback());
            })
        }
    }
    handleRefund = (charge_id, currentInvoice) => {
        const areYouSure = window.confirm(
            "Are you sure you want to refund this payment? Remember that you are only returning the value of an invoice without fee!"
        );
        areYouSure && this.props.actions.refund(charge_id, () => {
            this.props.actions.updateInvoice(currentInvoice, {paid_at: null, paid: false});
            this.reload();
            this.setState({showTermModal: null})
        })
    };

    render() {
        const {resource, selected_proposal_services, term_types, all_proposal_services} = this.state;

        return (
            <Grid fluid>
                <ProposalMenu
                    id={resource.id}
                    expandButton={false}
                    onReload={this.handleProposalIdChange}
                    onEmailSend={this.onEmailSend}
                />
                <Helmet
                    title={`${resource.proposal_no ? `Invoices for Proposal #${resource.proposal_no}` : 'Invoices'}`}
                />
                <Row>
                    <Col xs={12}>
                        <Well bsSize="small">
                            <Row>
                                <Col md={12}>
                                    {resource && this.renderEditWidget()}
                                </Col>
                            </Row>
                            {!isMobile && <Panel>
                                <Row>
                                    <Col md={1}>
                                        <label>
                                            <input
                                                disabled={all_proposal_services.filter(ps => ['Completed'].includes(ps.status) && !ps.invoice_id).length === 0}
                                                checked={
                                                    selected_proposal_services.length ===
                                                    all_proposal_services.filter(ps => ['Completed'].includes(ps.status) && !ps.invoice_id).length &&
                                                    all_proposal_services.filter(ps => ['Completed'].includes(ps.status) && !ps.invoice_id).length > 0
                                                }
                                                type="checkbox"
                                                onChange={e => this.handleSelectAllServices(e, resource && resource.proposal_services)}
                                            />
                                            {' '}
                                            #
                                        </label>
                                    </Col>
                                    <Col
                                        md={resource && resource.proposal_services && resource.enable_payments_client ? 1 : 2}>
                                        Service
                                    </Col>
                                    <Col md={1}>
                                        Priority
                                    </Col>
                                    <Col md={1}>
                                        Status
                                    </Col>
                                    <Col md={1}>
                                        Est Hrs
                                    </Col>
                                    <Col md={1}>
                                        $ Price
                                    </Col>
                                    <Col md={1}>
                                        WO #
                                    </Col>
                                    <Col md={1}>
                                        Invoice #
                                    </Col>
                                    <Col md={1}>
                                        Purchase Order
                                    </Col>
                                    <Col md={1}>
                                        Term
                                    </Col>
                                    <Col md={1}>
                                        Due Date
                                    </Col>
                                    {resource && resource.proposal_services && resource.enable_payments_client &&
                                        <Col md={1}>
                                            Enable Payments
                                        </Col>}
                                </Row>
                            </Panel>}
                            <Row>
                                <Col md={12}>
                                    {resource && resource.proposal_services && this.renderServiceList(resource.proposal_services)}
                                </Col>
                            </Row>
                        </Well>
                    </Col>
                </Row>
                <TermModal show={this.state.showTermModal} onHide={this.closeTermModal}
                           defaultTerm={this.state.defaultTerm} invoice={this.state.selectedInvoice}
                           newInvoiceAmount={this.state.newInvoiceAmount}
                           term_types={term_types}
                           onSave={this.updateTermAttributes}
                           onPaymentRefund={this.handleRefund}
                           onPaymentDelete={this.handlePaymentDelete}
                           onPaymentAdd={this.handlePaymentAdd}
                           onPaymentUpdate={this.handlePaymentUpdate}
                           loading={this.state.loading}
                           manualPaymentTypes={this.state.manualPaymentTypes}
                />
            {this.state.showPONumberModal && <PONumberInvoiceCreationModal
                term_types={term_types}
                defaultTerm={this.state.defaultTerm}
                handleSelectService={this.handleSelectService}
                handleSelectAllServices={this.handleSelectAllServices}
                proposalId={resource.id}
                save={this.save}
                selected_proposal_services={selected_proposal_services}
                all_proposal_services={all_proposal_services}
                proposal_services={resource.proposal_services}
                show={this.state.showPONumberModal}
                onClose={()=>{this.setState({showPONumberModal: false})}}
                onSaveShowTermModal={(amount)=>{this.setState({showTermModal: true, newInvoiceAmount: amount})}}
            />}
            </Grid>
        )
    }
}

const mapStateToProps = (state) => ({
    email: state.auth.email,
    client: state.client.customerInfo,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewInvoice)
