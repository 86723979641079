import React, {Component} from 'react';
import PropTypes from "prop-types"
import {Col, FormGroup, Grid, Row, Table, Button, Modal} from "react-bootstrap"
import Dollars from "../../components/Dollars"
import PlantName from "../../components/PlantName"
import ThDate from "../../components/ThDate"
import moment from "moment-timezone"
import StaticMap from "./StaticMap";
import {getUniqueAssets} from "../../utilities/commonProposals";
import './PrintProposal.css';
import {colors, defaultDateFormat, reverseColorArray, sortAssetsLabels} from "../../common/commonHandlers";
import Chart from 'chart.js'
import LabelWithText from "../../components/LabelWithText";
import {Editor as TinyEditor} from "@tinymce/tinymce-react";
import {Editor} from "react-draft-wysiwyg";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {MaterialSwitch} from "../../components/MaterialSwitch/MaterialSwitch";
import config from "../../config/environment";
import {filterProposalServices} from "../../common/pdfPrintUtils";

const google = window.google;
const isMobile = window.screen.width <= 1024;

Chart.Legend.prototype.afterFit = function () {
    const opts = this.options;
    if (typeof this.options.width === 'number') {
        this.minSize.width = opts.width;
        this.width = opts.width;
    }
    if (typeof this.options.height === 'number') {
        this.minSize.height = opts.height;
        this.height = opts.height;
    }
}

export class ProposalPrint extends Component {


    rendered = 0;

    state = {
        bounds: null,
        editTermsModal: false,
        confirmEditTermsModal: false,
        editorState: EditorState.createEmpty(),
        isTiny: true,
        tinyInitialValue: "",
        proposal: {
            terms_and_conditions: ""
        }
    };

    tinyMceRef = React.createRef();

    _renderMapSettings = (proposal) => {
        if (proposal.site_maps) {
            return proposal.site_maps.map((siteMap, index) =>
                this.props.options[`maps_${siteMap.id}`] ?
                    <div key={index}>
                        <br/>
                        {this._renderSiteMap(siteMap, proposal, index + 1, this.props.client)}
                        <br/>
                    </div> : '');
        }
    };

    drawSVGChart = (chartName, chartTitle) => {
        const {proposal_services} = this.props.proposal;
        const {fontSize} = this.props;

        const filteredProposalServices = filterProposalServices(proposal_services, this.props.options)

        const data = this.getChartsInfo(filteredProposalServices, chartName)
        const defaultOptions = {
            type: 'pie',
            options: {
                events: {
                    display: false
                },
                layout: {
                    padding: {
                        bottom: 0
                    },
                },
                title: {
                    display: true,
                    text: chartTitle,
                    padding: 10,
                    fontSize: fontSize.header + 2,
                    font: {
                        weight: 'lighter',
                        family: "'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                    },
                },
                legend: {
                    display: false,
                },
                legendCallback: chart => {
                    let html = '<div>';
                    chart.data.labels.forEach((l, i) => {
                        const ds = chart.data.datasets[0];
                        const bgColor = ds.backgroundColor[i];
                        const border = ds.borderWidth + 'px solid ' + ds.borderColor;
                        const legendFontSize = fontSize.header - 2;
                        html += '<span>' +
                            '<div class="box" style="width: 25px; height: 14px; background-color:' + bgColor + '!important' + '; border:' + border + '"></div>' +
                            '<span id="legend-label-' + i + '"' +' style="font-size: ' + legendFontSize + 'px"' + '>' +
                            (Array.isArray(l) ? l.join('<br/>') : l) + '</span>' +
                            '</span>';
                    });
                    return html + '</div>';
                },
                responsive: false,
                maintainAspectRatio: false,
                bezierCurve: false,
            }
        }

        const options = {
            ...defaultOptions,
            data: {
                labels: data.labels,
                datasets: [
                    {
                        data: data.counts,
                        backgroundColor: data.colors,
                        datalabels: {
                            color: reverseColorArray(data.colors),
                            align: 'end',
                        },
                        borderWidth: 1,
                    },
                ],
            }
        }

        const chartDOM = document.getElementById(`SVG-${chartName}`)
        chartDOM.classList.add("visible-desktop");
        const chart = new Chart(chartDOM, options);
        document.getElementById(`legend-${chartName}`).innerHTML = chart.generateLegend();
        return chart
    }

    onTinyEditorStateChange = (terms_and_conditions) => {
        const {proposal} = this.state
        proposal.terms_and_conditions = terms_and_conditions
        this.updateEditorState(terms_and_conditions)
        this.setState(proposal);
    };

    updateEditorState(message) {
        const contentBlock = htmlToDraft(message);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        this.setState({editorState});
    }

    removeDuplicates = (array, value) => {
        return array.reduce((unique, o) => {
            if (!unique.some(obj => obj[value] === o[value])) {
                unique.push(o);
            }
            return unique;
        }, []);
    }

    getChartsInfo = (services, chartName) => {
        const data = {}

        const allItems = []
        const assetColors = []
        const allAssets = []
        services.map(service => {
            switch (chartName) {
                case 'assetSummary':
                    allAssets.push(...service.assets)
                    break;
                case 'serviceType':
                    allItems.push(service.service_type)
                    break;
                case 'serviceSummary':
                    allItems.push(service.service_name)
                    break;
            }
        })
        if (chartName === 'assetSummary') {
            const assets = this.removeDuplicates(allAssets, 'asset_label')
            const serviceAssetsColors = allAssets.map(a => {
                return [a.name, parseInt(a.plant_color)]
            })
            allItems.push(...assets.map(a => a.name))
            assetColors.push(...serviceAssetsColors)
        }

        const items = allItems.reduce((acc, value) => ({
            ...acc,
            [value]: (acc[value] || 0) + 1
        }), {});

        data.labels = Object.keys(items);
        data.counts = Object.values(items);

        if (chartName === 'assetSummary') {
            const assetColorsDictionary = Object.fromEntries(assetColors);
            data.colors = data.labels.map(label => colors[assetColorsDictionary[label]])
        } else {
            data.colors = data.labels.map((label, index) => colors[index + 4])
        }

        return data;
    }

    renderPie = (chartName) => {

        return (
            <div className="chart-wrapper">
                <canvas width={200} height={200} id={`SVG-${chartName}`}/>
                <div id={`legend-${chartName}`}></div>
            </div>
        )
    }

    renderCharts = (proposal) => {

        if (proposal.proposal_services === undefined) {
            return null
        }

        const {assetSummary, serviceType, serviceSummary} = this.props;

        return (
            <div className='charts-align'>
                {assetSummary && this.renderPie('assetSummary')}
                {serviceType && this.renderPie('serviceType')}
                {serviceSummary && this.renderPie('serviceSummary')}
            </div>
        )
    }

    _renderSiteMap = (siteMap, proposal, idx, client) => {
        if (proposal.proposal_services === undefined) {
            return null
        }

        return (
            <div className="text-center map-print">
                <StaticMap
                    size="640x640"
                    zoom={siteMap.default_zoom || 20}
                    maptype={siteMap.map_type || 'hybrid'}
                    center={
                        `${siteMap.latitude || ((client && client.organization_latitude) ?
                            client.organization_latitude
                            : 40.0)},
                        ${siteMap.longitude || ((client && client.organization_longitude) ?
                            client.organization_longitude
                            : -105.0)}`}
                    proposalToken={proposal.token}
                    serviceOptions = {this.getSelectedServices(this.props.options)}
                    showDraft={true}
                    onRender={() => this.handleRender(proposal)}
                    mainMap={false}
                    fitBounds={this.props.options.fitBounds}
                />
            </div>
        )
    };

    handleRender = proposal => {
        this.rendered++;
        const {mapsToRender} = this.state;

        if (mapsToRender === this.rendered) {
            setTimeout(() => {
                window.status = 'rendered';
            }, 1000);
        }
    };

    getSelectedServices = (options) => {
        const serviceOptions = {
            servicesSpecificallyNotOffered: options.servicesSpecificallyNotOffered,
            servicesDraft: options.servicesDraft,
            servicesScheduled: options.servicesScheduled,
            servicesInWorkOrder: options.servicesInWorkOrder,
            servicesDeclined: options.servicesDeclined,
            servicesAccepted: options.servicesAccepted,
            servicesProposed: options.servicesProposed,
            servicesCompleted: options.servicesCompleted,
            servicesInvoiced: options.servicesInvoiced,
            servicesPayment: options.servicesPayment,
            servicesPaid: options.servicesPaid,
        }

        return Object.fromEntries(Object.entries(serviceOptions).filter(([key, value]) => value === true));
    }

    setMapMarginBottom = () => {
        const {logoPosition, showLogoHeader} = this.props
        const {marginBottom} = this.props.options

        const logoOptions = logoPosition !== 'center'

        if (marginBottom) {
            return marginBottom
        } else if (!showLogoHeader) {
            return 200
        } else if (showLogoHeader && logoOptions) {
            return 60
        } else {
            return 145
        }
    }

    renderSiteMap = (proposal, mapNum, assets, client) => {
        const { marginTop } = this.props.options
        const top = marginTop ? marginTop : 15

        if (proposal.proposal_services === undefined) {
            return null
        }

        return (
            <div className={`text-center map-wrapper`}
                 style={{marginTop: `${top}px`, marginBottom: `${this.setMapMarginBottom()}px`}}>
                <h3>Asset Map</h3>
                <StaticMap
                    mainMap={true}
                    size="640x640"
                    zoom={proposal.site_zoom || 20}
                    maptype={proposal.site_map_type || "hybrid"}
                    center={(proposal.site_lat && proposal.site_lng) ? (`${proposal.site_lat},${proposal.site_lng}`) : ((client && client.organization_longitude) ?
                        `${client.organization_latitude}, ${client.organization_longitude}`
                        : '40.0, -105.0')}
                    proposalToken={proposal.token}
                    serviceOptions = {this.getSelectedServices(this.props.options)}
                    showDraft={true}
                    onRender={() => this.handleRender(proposal)}
                    siteMap={0}
                    fitBounds={this.props.options.fitBounds}
                />
            </div>
        );
    };

    renderPhotos = proposal => {
        if (proposal.proposal_services === undefined) {
            return null
        }

        const isMobile = window.screen.width <= 425;

        let photos = [];
        const assets = getUniqueAssets(proposal).sort((a, b) => sortAssetsLabels(a, b, 'asset_label'));
        assets.forEach(a => {
            a.photos.filter(p => p.cwo_and_proposal_display === true).sort((a, b) => a.order - b.order).forEach(p => {
                photos.push(
                    <Table bordered key={p.id} className="photo">
                        <thead/>
                        <tbody>
                        <tr>
                            <td className="text-center" style={{padding: isMobile && 0}}>
                                <img alt="tree" width={isMobile && window.screen.width - 60} src={p.url}/>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">
                                #{a.asset_label}{" "}<PlantName plant={a.name}/>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                )
            })
        });

        if (photos.length === 0) {
            photos = null
        } else {
            return (
                <Row className="avoid_page_breaking_inside">
                    <Col xs={12}>
                        <h5>Photos</h5>
                        {photos}
                    </Col>
                </Row>
            )
        }
    };

    renderCustomerPhotos = proposal => {
        const isMobile = window.screen.width <= 425;

        let photos = [];
        proposal.customer_files.sort((a, b) => a.order - b.order).forEach(p => {
            photos.push(
                <Table bordered key={p.id} className="photo">
                    <thead/>
                    <tbody>
                    <tr>
                        <td className="text-center" style={{padding: isMobile && 0}}>
                            <img alt="tree" width={isMobile && window.screen.width - 60} src={p.original}/>
                        </td>
                    </tr>
                    <tr>
                        <td className="text-center">
                            <PlantName plant={p.name}/>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            )
        })

        if (photos.length === 0) {
            photos = null
        } else {
            return (
                <Row className="avoid_page_breaking_inside">
                    <Col xs={12}>
                        <h5>User's Photos</h5>
                        {photos}
                    </Col>
                </Row>
            )
        }
    };

    renderSitesPhotos = proposal => {
        const isMobile = window.screen.width <= 425;

        let photos = [];
        proposal.sites_files.sort((a, b) => a.order - b.order).forEach(p => {
            photos.push(
                <Table bordered key={p.id} className="photo">
                    <thead/>
                    <tbody>
                    <tr>
                        <td className="text-center" style={{padding: isMobile && 0}}>
                            <img alt="tree" width={isMobile && window.screen.width - 60} src={p.original}/>
                        </td>
                    </tr>
                    <tr>
                        <td className="text-center">
                            <PlantName plant={p.name}/>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            )
        })

        if (photos.length === 0) {
            photos = null
        } else {
            return (
                <Row className="avoid_page_breaking_inside">
                    <Col xs={12}>
                        <h5>Sites Photos</h5>
                        {photos}
                    </Col>
                </Row>
            )
        }
    };

    renderServices = proposal => {
        const { dbh, condition, lat, lng, height, width, stems, factors, wrapVertically } = this.props.options;
        const {serviceSummary, serviceType, assetSummary} = this.props
        const {thumbnailSize, wrapHorizontally} = this.props.options
        let showCharts;

        if (serviceSummary || serviceType || assetSummary) {
            showCharts = true
        }

        const proposal_services = filterProposalServices(proposal.proposal_services, this.props.options)

        return (
            <div className='services-page'>
                {proposal_services &&
                    proposal_services
                        .slice()
                        .sort((a, b) => a.service_no - b.service_no)
                        .map((ps, i) => {
                            if (
                                !(this.props.options.servicesNotOffered || this.props.options.servicesSpecificallyNotOffered) &&
                                ps.status === "Not Offered"
                            ) {
                                return <div className="skip_not_offered"/>
                            }

                            return <div className='single-service'>
                                <div className='header-row'>
                                    <strong className='counter'>#{ps.service_no}</strong>{' '}
                                    <strong>{ps.service_name}</strong>{' '}
                                    {this.props.options.proposalServiceStatus && <span className={`proposal-service-status ${ps.status.toLowerCase()}`}>({ps.status})</span>}
                                    {this.props.options.servicePrices ?
                                        <span className='price'><Dollars amount={ps.price}/></span>
                                        :
                                        null
                                    }
                                </div>
                                {this.props.options.servicePurchaseOrder && ps.purchase_order && <div className='po-number'>
                                    <span>
                                        <strong>Purchase Order:</strong> {ps.purchase_order}<br/>
                                    </span>
                                </div>}
                                <div className='service-details'>
                                    <strong>
                                        {this.props.options.servicePriorities && ps.priorities && ps.priorities.length > 0 ? 'Priority: ' : null}
                                        {this.props.options.servicePriorities && ps.priorities && ps.priorities.length > 0 &&
                                            ps.priorities.map(p => <span
                                                key={p.id}>{p.name}{ps.priorities.length > 1 ? ', ' : ' '}</span>)}
                                    </strong>
                                    {this.props.options.servicePriorities && ps.priority
                                        ? <br/>
                                        : null}

                                    {this.props.options.serviceDateRanges && ps.date_ranges.length
                                        ? ps.date_ranges.map(dr => (
                                            <div key={dr.id}>
                                                Typical Timing:
                                                {" "}
                                                {moment(dr.from_date).format("MMMM Do")}
                                                {" "}
                                                -
                                                {" "}
                                                {moment(dr.to_date).format("MMMM Do")}
                                            </div>
                                        ))
                                        : null}

                                    {this.props.options.proposalServiceNotes &&
                                        <span className='pre-line'>{ps.proposal_service_note}</span>}
                                    {ps.is_bundle_service && ps.bundle_services.sort((a, b) => a.service_number - b.service_number).map(bs => {
                                        return (
                                            <div key={bs.id} className='mt10'>
                                                <div>
                                                    <strong className='ml-9'>{bs.name}</strong>
                                                </div>
                                                <span className='pre-line'>
                                                    {bs.notes}
                                                </span>
                                                <div className='mt10'>
                                                    <strong className='trees '>Trees</strong>
                                                </div>
                                                <div className='assets-container'>
                                                    {bs.assets.sort((a, b) => sortAssetsLabels(a, b, 'asset_label')).map(asset => {
                                                        if (asset.image_order) {
                                                            let order = JSON.parse(asset.image_order);
                                                            asset.photos = asset.photos.map(img => {
                                                                img.order = order.findIndex((o) => o === img.id)
                                                                return img
                                                            })
                                                            return asset
                                                        } else {
                                                            asset.photos = asset.photos.map((img, index) => {
                                                                img.order = index
                                                                return img
                                                            })
                                                            return asset
                                                        }
                                                    }).map((a, i) => (
                                                        <div
                                                            className={`asset-wrapper` + `${this.props.options.assInline && (!this.props.options.thumbAssetPhotos || a.photos.length < 2) ? '-inline' : ""}`}
                                                            style={{width: this.props.options.assInline && (!this.props.options.thumbAssetPhotos || a.photos.length < 2) ? '30%' : '100%'}}
                                                            key={a.asset_label}>

                                                            <strong>
                                                                #{a.asset_label} <PlantName plant={a.name}/> {" "}
                                                            </strong>
                                                            <span
                                                                className={`asset-details` + `${wrapVertically ? '-vertically' : ""}`}>
                                                <span>{a.plant_count > 1 ? ` ${a.plant_count} Trees` : null}</span>
                                                <span>{dbh && a.dbh ? ` DBH: ${a.dbh}"` : null}</span>
                                                <span>{condition && a.condition_type ? ` Condition: "${a.condition_type}"` : null}</span>
                                                <span>{lat && a.lat ? ` Latitude: ${a.lat}` : null}</span>
                                                <span>{lng && a.lng ? ` Longitude: ${a.lng}` : null}</span>
                                                <span>{height && a.height ? ` Height: ${a.height}` : null}</span>
                                                <span>{width && a.width ? ` Width: ${a.width}` : null}</span>
                                                <span>{stems && a.stems ? ` Stems: ${a.stems}` : null}</span>
                                                <span>{this.props.options.locationDescription && a.location
                                                    ? ` Location: ${a.location}`
                                                    : null}</span>
                                                <span> {this.props.options.assetNotes && a.note && a.note.length > 1
                                                    ? ` Note: ${a.note}  `
                                                    : null}</span>
                                                <p>{(this.props.options.thumbAssetPhotos && a.photos.length > 0) ? a.photos.filter(p => p.cwo_and_proposal_display === true).sort((a, b) => a.order - b.order).map(p =>
                                                    <span
                                                        className={wrapHorizontally ? `thumbnails-horizontally` : `thumbnails-vertically`}>
                                                        <img src={p.url}
                                                             style={{height: thumbnailSize || 180}}/></span>) : ""}
                                                </p>
                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )
                                    })

                                    }
                                </div>
                                {!ps.is_bundle_service &&
                                    <>
                                        <strong className='trees'>Trees</strong>
                                        <div className='assets-container'>
                                            {ps.assets.sort((a, b) => sortAssetsLabels(a, b, 'asset_label')).map(asset => {
                                                if (asset.image_order) {
                                                    let order = JSON.parse(asset.image_order);
                                                    asset.photos = asset.photos.map(img => {
                                                        img.order = order.findIndex((o) => o === img.id)
                                                        return img
                                                    })
                                                    return asset
                                                } else {
                                                    asset.photos = asset.photos.map((img, index) => {
                                                        img.order = index
                                                        return img
                                                    })
                                                    return asset
                                                }
                                            }).map((a, i) => (
                                                <div
                                                    className={`asset-wrapper` + `${this.props.options.assInline && (!this.props.options.thumbAssetPhotos || a.photos.length < 2) ? '-inline' : ""}`}
                                                    style={{width: this.props.options.assInline && (!this.props.options.thumbAssetPhotos || a.photos.length < 2) ? '30%' : '100%'}}
                                                    key={a.asset_label}>

                                                    <strong>
                                                        #{a.asset_label} <PlantName plant={a.name}/> {" "}
                                                    </strong>
                                                    <span
                                                        className={`asset-details` + `${wrapVertically ? '-vertically' : ""}`}>
                                                <span>{a.plant_count > 1 ? ` ${a.plant_count} Trees` : null}</span>
                                                <span>{dbh && a.dbh ? ` DBH: ${a.dbh}"` : null}</span>
                                                <span>{condition && a.condition_type ? ` Condition: "${a.condition_type}"` : null}</span>
                                                <span>{lat && a.lat ? ` Latitude: ${a.lat}` : null}</span>
                                                <span>{lng && a.lng ? ` Longitude: ${a.lng}` : null}</span>
                                                <span>{height && a.height ? ` Height: ${a.height}` : null}</span>
                                                <span>{width && a.width ? ` Width: ${a.width}` : null}</span>
                                                <span>{stems && a.stems ? ` Stems: ${a.stems}` : null}</span>
                                                <span>{factors && a.factors ? ` Factors: ${a.factors.map(f => ' ' + f.name)}` : null}</span>
                                                <span>{this.props.options.locationDescription && a.location
                                                    ? ` Location: ${a.location}`
                                                    : null}</span>
                                                <span> {this.props.options.assetNotes && a.note && a.note.length > 1
                                                    ? ` Note: ${a.note}  `
                                                    : null}</span>
                                                <p>{(this.props.options.thumbAssetPhotos && a.photos.length > 0) ? a.photos.filter(p => p.cwo_and_proposal_display === true).sort((a, b) => a.order - b.order).map(p =>
                                                    <span
                                                        className={wrapHorizontally ? `thumbnails-horizontally` : `thumbnails-vertically`}>
                                                        <img src={p.url}
                                                             style={{height: thumbnailSize || 180}}/></span>) : ""}
                                                </p>
                                            </span>
                                                </div>
                                            ))}
                                        </div>
                                    </>}
                            </div>
                        })
                }
            </div>
        )
    };

    renderProposalNote = proposal => (
        <Row className="avoid_page_breaking_inside">
            <Col xs={12}>
                <p>&nbsp;</p>
                <em style={{whiteSpace: 'pre-line'}}>
                    {proposal.proposal_customer_note || ""}
                </em>
                <p>&nbsp;</p>
            </Col>
        </Row>
    );

    renderPageHeader = proposal => {
        const {address, city, state, zip} = proposal.site_contact_address
        const servicesPO = proposal.proposal_services
            .sort((a, b) => a.service_no - b.service_no)
            .map(ps => ps.purchase_order)
            .filter(po => !!po)

        return (
            <Row className={`proposal-page-header`}>
                <Col md={6} xs={12}>
                    <div className="pull-left">
                        Proposed: <ThDate dateString={proposal.proposal_date}/>
                        <h3 style={{marginTop: '0px', marginBottom: '0px'}}>
                            {proposal.customer_name}
                        </h3>
                        <span className='title'>{proposal.proposal_title}</span><br/>
                        <span><strong>Purchase Order: </strong>
                            {proposal.purchase_order ?? '-'}
                            {this.props.options.servicePurchaseOrder && servicesPO.length > 0 && (
                                servicesPO.map((service, index) => (index < servicesPO.length ? ', ' : '') + service)
                            )}</span>
                        <br/>
                        <LabelWithText label="Proposal" text={proposal.proposal_no}/>
                        <LabelWithText label="Contact" text={proposal.site_contact_name}/>
                        <span className="font-weight-bold">Phone: </span>
                        <a media="screen" href={`tel:${proposal.customer_phone.replace(/\\D/g, '')}`}>
                            {proposal.customer_phone}
                        </a><br/>
                        <span className="font-weight-bold">Email: </span>
                        <a media="screen" href={`mailto:${proposal.customer_email_address}`}>
                            {proposal.customer_email_address}
                        </a><br/>
                        <LabelWithText label="Site" text={proposal.site_name}/>
                        <span className="font-weight-bold">Address: </span>
                        {address}{" "}
                        {proposal.customer_address_2 ? proposal.customer_address_2 : null}
                        {proposal.customer_address_2 ? <br/> : null}
                        {city}, {state}{" "}
                        {zip}
                    </div>
                </Col>
                {(this.props.options.arboristInfo || this.props.options.showCompanyAddress) &&

                    <Col md={6} xs={12} className={`text-left proposal-info ${!isMobile && 'hright'}`}>
                        <div>
                            {this.props.options.arboristInfo && <>
                            <LabelWithText label="Arborist" text={proposal.sales_arborist_name}/>
                                <LabelWithText label="Phone" text={proposal.arborist_phone}/>
                                <LabelWithText label="Email" text={proposal.arborist_email}/>
                            </>}
                            {this.props.options.showCompanyAddress && <LabelWithText label="Company address" text={`${proposal.client_address}, ${proposal.client_city}, ${proposal.client_state} ${proposal.client_zip}`}/>}
                        </div>
                    </Col>}
            </Row>
        )
    };

    renderTermsAndConditions = proposal => {
        return (
            <Row className="terms-wrapper top50">
                {proposal.terms && <div className="text-right">
                    <Button bsStyle="success" onClick={() => proposal.allow_edit_proposal_terms ? this.setState({editTermsModal: true}) : this.setState({confirmEditTermsModal: true})}>Edit Terms and Conditions</Button>
                </div>}
                <Col md={12} style={{fontSize: "initial"}}>
                    <FormGroup>
                        <div dangerouslySetInnerHTML={{__html: proposal.terms}}/>
                    </FormGroup>
                </Col>
            </Row>
        )
    };

    termsModal = proposal => {
        const {isTiny} = this.state

        return (
            <Modal
                id="print-proposal-terms-edit-modal"
                bsSize="large"
                show={this.state.editTermsModal}
                className={"heightAuto fontIOS"}
            >
                <Modal.Header closeButton><Modal.Title>Terms and Conditions</Modal.Title></Modal.Header>
                <Modal.Body className="max-height-600">
                    <Row>
                        <Col xs={12} className="mt10">
                            <MaterialSwitch
                                value={isTiny}
                                onChange={() => {this.setState({isTiny: !isTiny})}}
                                label={"Wysiwyg"}
                                label2={"TinyMCE"}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} style={{maxHeight: '620px', overflow: "scroll"}}>
                            {isTiny ?
                                <TinyEditor
                                    apiKey={config.tinyMceKey}
                                    onInit={(evt, editor) => this.tinyMceRef.current = editor}
                                    initialValue={this.state.tinyInitialValue}
                                    value={this.state.proposal.terms_and_conditions}
                                    onEditorChange={(newValue) => this.onTinyEditorStateChange(newValue)}
                                    init={{
                                        convert_urls: false,
                                        height: 500,
                                        menubar: true,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        toolbar_mode: 'wrap',
                                        mobile: {
                                            convert_urls: false,
                                            menubar: true,
                                            toolbar_mode: 'wrap',
                                            plugins: [
                                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                            ],
                                        }
                                    }}
                                />
                                :
                                <Editor
                                    editorState={this.state.editorState}
                                    onEditorStateChange={(editorState) => {
                                        this.setState({editorState, proposal: {...proposal, terms_and_conditions: draftToHtml(convertToRaw(editorState.getCurrentContent()))}});
                                    }}
                                    spellCheck
                                />
                            }
                        </Col>
                        <Col md={6} className="terms-preview">
                            <FormGroup>
                                <div dangerouslySetInnerHTML={{__html: isTiny ? this.state.proposal.terms_and_conditions : draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))}}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" onClick={() => {
                        const contentBlock = htmlToDraft(proposal.terms || '');
                        if (contentBlock) {
                            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                            const editorState = EditorState.createWithContent(contentState);
                            this.setState({editorState, proposal: {...proposal, terms_and_conditions: proposal.terms}, editTermsModal: false})
                        }
                    }}
                            className="btn btn-default"
                            data-dismiss="modal">Cancel
                    </Button>
                    <Button className="btn btn-success" onClick={() => {
                        proposal.terms = this.state.proposal.terms_and_conditions
                        this.props.updateTerms(proposal, () => this.setState({editTermsModal: false, tinyInitialValue: proposal.terms}))
                    }}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    confirmEditTermsModal = () => {
        return (
            <Modal show={this.state.confirmEditTermsModal}
                   className={"heightAuto fontIOS"}
                   animation={false}
            >
                <Modal.Header className="text-center">
                    <h4>This proposal has been accepted. Are you sure you want to change Terms & Conditions?</h4>
                </Modal.Header>
                <Modal.Footer>
                    <div className="text-center">
                        <Button className="btn btn-warning" onClick={() => this.setState({confirmEditTermsModal: false})}>
                            Cancel
                        </Button>
                        <Button className="btn btn-success" onClick={() => this.setState({confirmEditTermsModal: false, editTermsModal: true})}>
                            Confirm
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }

    renderProposalTotal = proposal => {
        if (proposal.proposal_services === undefined) {
            return null
        }

        const total = proposal.proposal_services.reduce((total, ps) => {
            return total + (ps.status !== "Not Offered" ? ps.price : 0)
        }, 0);

        return (
            <Row className="avoid_page_breaking_inside mr-0-ml-0">
                <hr/>
                <Col xs={12} className="text-right">
                    <p>
                        <strong>
                            Subtotal: {<Dollars amount={total}/>}
                        </strong>
                    </p>

                    <p>
                        <strong>
                            Tax: {<Dollars amount={total * proposal.tax}/>}
                        </strong>
                    </p>

                    <p>
                        <strong>
                            Total Price: {<Dollars amount={total + total * proposal.tax}/>}
                        </strong>
                    </p>
                </Col>
            </Row>
        )
    };

    renderCustomerSignatureLine = (proposal) => {
        const signature = JSON.parse(proposal.signature)
        return (
            <div className="avoid_page_breaking_inside">
                {
                    this.props.options.proposal_footer &&
                    <Row className="top25 ml0">
                        {proposal.proposal_footer}
                    </Row>
                }
                {(this.props.options.showSignature || this.props.options.showSignatureData) && proposal.signature ?
                    <Row className="ml0" style={{height: 155}}>
                        <Col xs={4} className="margin-only-5-top full-height d-grid-bottom">
                            {this.props.options.showSignature &&
                                <div className="text-center">
                                    <img src={signature.signature} style={{width: 300, justifySelf: "center"}}/>
                                    <p><strong>{signature.first_name}{" "}{signature.last_name}</strong></p>
                                </div>
                            }
                            <p style={{textAlign: "center", borderTop: "1px solid black"}}>
                                Customer Signature
                            </p>
                        </Col>
                        <Col xs={1}/>
                        <Col xs={2} className="full-height d-grid-bottom min-fit-content" style={{alignContent: "end"}}>
                            {this.props.options.showSignatureData &&
                                <div className="margin-only-5-top padding5">
                                    <p>Edited by: {signature.edited_by}</p>
                                    <p>Term accepted date: {moment(signature.date).format(defaultDateFormat)}</p>
                                    <p>IP: {signature.ip}</p>
                                </div>
                            }
                            <p style={{textAlign: "center", borderTop: "1px solid black"}}>
                                Date
                            </p>
                        </Col>
                    </Row>
                    :
                    <Row className="top50 ml0">
                        <Col xs={4} style={{textAlign: "center", borderTop: "1px solid black"}}>
                            Customer Signature
                        </Col>
                        <Col xs={1}/>
                        <Col xs={2} style={{textAlign: "center", borderTop: "1px solid black"}}>
                            Date
                        </Col>

                    </Row>
                }
            </div>
        )
    };

    renderClientHeader = proposal => {

        const isMobile = window.screen.width <= 768
        const logoPosition = this.props.logoPosition && this.props.logoPosition === 'center' ? 'unset' : this.props.logoPosition

        return (
            <Row>
                <Col md={12}>
                    {this.props.showLogoHeader && <Row>
                        <Col xs={12} className="text-center">
                            <img
                                height={this.props.logoHeight}
                                style={{float: logoPosition}}
                                className="logoHeader"
                                width={isMobile && window.screen.width - 60}
                                alt="company header"
                                src={proposal.client_header}
                            />
                        </Col>
                    </Row>}
                </Col>
            </Row>
        );
    }

    drawCharts = () => {
        const charts = [];
        const chartsArray = [
            {name: 'assetSummary', title: 'Asset Summary'},
            {name: 'serviceSummary', title: 'Service Summary'},
            {name: 'serviceType', title: 'Service Types'}
        ]

        chartsArray.forEach((chart) => {
            if (this.props[chart.name]) {
                const chartObject = this.drawSVGChart(chart.name, chart.title);
                charts.push(chartObject);
            }
        })

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.serviceSummary !== prevProps.serviceSummary ||
            this.props.assetSummary !== prevProps.assetSummary ||
            this.props.serviceType !== prevProps.serviceType ||
            this.props.servicesNotOffered !== prevProps.servicesNotOffered ||
            this.props.servicesSpecificallyNotOffered !== prevProps.servicesSpecificallyNotOffered ||
            this.props.servicesDraft !== prevProps.servicesDraft ||
            this.props.servicesScheduled !== prevProps.servicesScheduled ||
            this.props.servicesInWorkOrder !== prevProps.servicesInWorkOrder ||
            this.props.servicesDeclined !== prevProps.servicesDeclined ||
            this.props.servicesAccepted !== prevProps.servicesAccepted ||
            this.props.servicesProposed !== prevProps.servicesProposed ||
            this.props.servicesCompleted !== prevProps.servicesCompleted ||
            this.props.servicesInvoiced !== prevProps.servicesInvoiced ||
            this.props.servicesPayment !== prevProps.servicesPayment ||
            this.props.servicesPaid !== prevProps.servicesPaid ||
            this.props.fontSize.header !== prevProps.fontSize.header) {
            this.drawCharts()
        }
        if (this.props.proposal?.id !== prevProps?.proposal?.id) {
            const contentBlock = htmlToDraft(this.props.proposal.terms || '');
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                this.setState({editorState, tinyInitialValue: this.props.proposal.terms || ""})
            }
        }
        if (prevState.isTiny !== this.state.isTiny) {
            const contentBlock = htmlToDraft(this.props.proposal.terms || '');
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                this.setState({editorState, tinyInitialValue: this.props.proposal.terms || ""})
            }
        }
        if ((prevState.editTermsModal !== this.state.editTermsModal) && this.state.editTermsModal ) {
            this.setState({proposal: {terms_and_conditions: this.props.proposal.terms}})
        }
    }

    componentDidMount() {
        const {options, proposal} = this.props;
        const siteMapsCount = proposal.site_maps.map((siteMap) =>
            this.props.options[`maps_${siteMap.id}`] ? 1 : 0).reduce((sum, x) => sum + x, 0);
        const mapsToRender = +siteMapsCount + 1;
        this.drawCharts();
        this.setState({
            mapsToRender
        }, () => {
            if (mapsToRender === 0) {
                setTimeout(() => {
                    window.status = 'rendered';
                }, 3000);
            }
        });
        const contentBlock = htmlToDraft(proposal.terms || '');
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.setState({editorState, tinyInitialValue: this.props.proposal.terms || ""})
        }
    }

    render() {
        const {proposal, options, assets, fontSize} = this.props;
        const {mapsToRender, editTermsModal, confirmEditTermsModal} = this.state;
        const {atTheEnd} = this.props.options
        return (<Grid id="print_proposal">
                <Row>
                    <Col md={12} xs={12}>
                        <div style={{"font-size": `${this.props.fontSize.header}px`}}>
                            {this.renderClientHeader(proposal)}

                            {options.proposalCustomerNote && !options.notesAfterServices && !options.notesBeforeServices &&
                                this.renderProposalNote(proposal)}

                            {this.renderPageHeader(proposal)}

                            {this.renderCharts(proposal)}
                        </div>
                        <div style={{"font-size": `${this.props.fontSize.content}px`}}>
                            {!atTheEnd && options.maps && mapsToRender && this.renderSiteMap(proposal, 1, assets, this.props.client)}
                            {!atTheEnd && this._renderMapSettings(proposal)}
                            {options.proposalCustomerNote && options.notesBeforeServices && this.renderProposalNote(proposal)}
                            {options.positionCustomerAttachments === "before_services" && options.customerAttachments &&
                                this.renderCustomerPhotos(proposal)}
                            {options.positionSitesAttachments === "before_services" && options.sitesAttachments &&
                                this.renderSitesPhotos(proposal)}

                            {this.renderServices(proposal)}

                            {options.positionCustomerAttachments === "after_services" && options.customerAttachments &&
                                this.renderCustomerPhotos(proposal)}

                            {options.positionSitesAttachments === "after_services" && options.sitesAttachments &&
                                this.renderSitesPhotos(proposal)}

                            {options.proposalCustomerNote && options.notesAfterServices && this.renderProposalNote(proposal)}
                        </div>

                        <div style={{"font-size": `${this.props.fontSize.footer}px`}}>
                            {options.proposalTotal && this.renderProposalTotal(proposal)}

                            {this.renderCustomerSignatureLine(proposal)}
                        </div>

                        <div style={{"font-size": `${this.props.fontSize.content}px`}}>
                            {options.positionCustomerAttachments === "before_images" && options.customerAttachments &&
                                this.renderCustomerPhotos(proposal)}

                            {options.positionSitesAttachments === "before_images" && options.sitesAttachments &&
                                this.renderSitesPhotos(proposal)}

                            {options.largeAssetPhotos && this.renderPhotos(proposal)}

                            {options.positionCustomerAttachments === "after_images" && options.customerAttachments &&
                                this.renderCustomerPhotos(proposal)}

                            {options.positionSitesAttachments === "after_images" && options.sitesAttachments &&
                                this.renderSitesPhotos(proposal)}

                            {options.assInline}

                            {atTheEnd && options.maps && mapsToRender && this.renderSiteMap(proposal, 1, assets, this.props.client)}

                            {atTheEnd && this._renderMapSettings(proposal)}
                        </div>

                        {options.tos && this.renderTermsAndConditions(proposal)}

                        {editTermsModal && this.termsModal(proposal)}
                        {confirmEditTermsModal && this.confirmEditTermsModal()}
                    </Col>
                </Row>
            </Grid>
        )
    }
}

ProposalPrint.propTypes = {
    proposal: PropTypes.object.isRequired,
    options: PropTypes.object
};
